import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class PersonalDocumentTypeService extends StaticDataService {
  constructor() {
    super();
    this.data = [
      {
        id: 0,
        name: 'RG'
      },
      {
        id: 1,
        name: 'CNH'
      },
      {
        id: 2,
        name: 'Passaporte'
      }
    ];
  }
}
