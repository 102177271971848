<h2 class="mt-10 text-blue">Dados de Constituição</h2>
<form [formGroup]="form">
  <div>
    <div
      *ngIf="cnae?.invalid && (cnae?.dirty || cnae?.touched)"
      class="invalid-feedback"
    >
      <div *ngIf="cnae?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>CNAE</mat-label>
      <input
        formControlName="cnae"
        [(ngModel)]="_constitutionData.cnae"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div>
    <div
      *ngIf="
        numberEmployees?.invalid &&
        (numberEmployees?.dirty || numberEmployees?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="numberEmployees?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Número de Funcionários</mat-label>
      <input
        formControlName="numberEmployees"
        [(ngModel)]="_constitutionData.numberEmployees"
        type="number"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div>
    <div
      *ngIf="bank?.invalid && (bank?.dirty || bank?.touched)"
      class="invalid-feedback"
    >
      <div *ngIf="bank?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Banco</mat-label>
      <input
        formControlName="bank"
        [(ngModel)]="_constitutionData.bank"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="
          bankAgency?.invalid && (bankAgency?.dirty || bankAgency?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="bankAgency?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Agência</mat-label>
        <input
          formControlName="bankAgency"
          [(ngModel)]="_constitutionData.bankAgency"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          bankAccount?.invalid && (bankAccount?.dirty || bankAccount?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="bankAccount?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Conta Corrente</mat-label>
        <input
          formControlName="bankAccount"
          [(ngModel)]="_constitutionData.bankAccount"
          required
          matInput
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div
      *ngIf="
        companyCapital?.invalid &&
        (companyCapital?.dirty || companyCapital?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="companyCapital?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Capital Social</mat-label>
      <mat-select
        formControlName="companyCapital"
        [(ngModel)]="_constitutionData.companyCapital"
        required
      >
        <mat-option
          *ngFor="let companyCapital of companyCapitals"
          [value]="companyCapital.id"
          >{{ companyCapital.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <h2 class="mt-10 text-blue">
    Procuradores / Administradores / Representantes Legais
  </h2>
  <div formArrayName="representatives">
    <app-representative-data-form
      *ngFor="let representative of representatives().controls; let i = index"
      (filesChange)="updatedRepresentativeFiles(i, $event)"
      (increaseRepresentative)="addRepresentativeData()"
      (removeRepresentative)="removeRepresentativeData(i)"
      [representativeNumber]="i"
      [formRepresentative]="representative"
      [representative]="_constitutionData?.representatives?.[i]"
    >
    </app-representative-data-form>
  </div>

  <h2 class="mt-10 text-blue">Sócios</h2>
  <div formArrayName="partners">
    <app-partner-data-form
      *ngFor="let partner of partners().controls; let i = index"
      (filesChange)="updatedPartnerFiles(i, $event)"
      (increasePartner)="addPartnerData()"
      (removePartner)="removePartnerData(i)"
      [partnerNumber]="i"
      [formPartner]="partner"
      [partner]="_constitutionData?.partners?.[i]"
    >
    </app-partner-data-form>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="next()">
      Prosseguir
    </button>
  </div>
</form>
