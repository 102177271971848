import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SensediaService } from '../services/sensedia.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sensediaService: SensediaService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let authReq = req;
    // const token = this.sensediaService.getToken();
    // if (token != null) {
    //   authReq = this.addTokenHeader(req, token);
    // }
    // return next.handle(authReq).pipe(catchError(error => {
    //   if (error instanceof HttpErrorResponse && !authReq.url.includes('auth/signin') && error.status === 401) {
    //     return this.handle401Error(authReq, next);
    //   }
    //   return throwError(error);
    // }));
    // const headers = {
    //   'Content-Type': 'application/json',
    //   Accept: 'application/json',
    //   Authorization: ''
    // };
    // return new Observable((observer: any) => {
    // this.sensediaService.getAccessToken().then((accessToken: string) => {
    //   if (accessToken) {
    //     headers['Authorization'] = `Bearer ${accessToken}`;
    //   }
    //
    //   // const request = req.clone({ setHeaders: headers });
    //
    // });
    // });
    return next
      .handle(req)
      .pipe(catchError((error) => this.handleError(error, req, next)));
  }

  private handleError(
    err: HttpErrorResponse,
    _request: HttpRequest<any>,
    _next: HttpHandler
  ): Observable<any> {
    if (err.status == 401) {
      this.authService.logout();
      this.router.navigate(['/autenticacao']);
    }
    return throwError(() => err);
  }
}
