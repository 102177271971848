import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegalPerson } from '../../../../types/profile/legal-person.type';

@Component({
  selector: 'app-constitution-data-detail-pj',
  templateUrl: './constitution-data-detail-pj.component.html',
  styleUrls: ['./constitution-data-detail-pj.component.scss']
})
export class constitutionDataDetailPjComponent {
  @Input() legalPerson: LegalPerson;
  @Output() previousChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<void>();

  constructor() {
    this.legalPerson = {} as LegalPerson;
  }

  previous() {
    this.previousChange.emit();
  }

  edit() {
    this.editChange.emit();
  }
}
