import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { defaultFormErros } from 'src/app/shared/enums/default-form-errors.enum';
import { FormErrorType } from 'src/app/shared/types/form-error.type';
import { StaticDataType } from '../../../types/static-data.type';
import { MaritalStatusService } from '../../../services/static-data/marital-status.service';
import { RepresentativeTypeService } from '../../../services/static-data/representative-type.service';
import { FileType } from '../../../types/profile/file.type';
import { FormService } from '../../../services/form.service';
import { ProfileFileService } from '../../../services/profile/profile-file.service';
import { profileFileAccepted } from '../../../data/profile-files-accepted';
import { Validators } from '@angular/forms';
import { CustomValidator } from '../../../custom.validator';
import { v4 as uuidv4 } from 'uuid';
import { defaultMessages } from '../../../data/default-messages';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RepresentativeType } from '../../../types/profile/representative.type';

@Component({
  selector: 'app-representative-data-form',
  templateUrl: './representative-data-form.component.html',
  styleUrls: ['./representative-data-form.component.scss']
})
export class RepresentativeDataFormComponent implements OnInit {
  private representativeId = uuidv4();
  errorMessages: FormErrorType = defaultFormErros;
  isAdministrator: boolean;
  profileFileAccepted: string = profileFileAccepted;
  representativeTypes: Array<StaticDataType>;
  maritalStatuses: Array<StaticDataType>;
  _representativeNumber!: number;
  _files: { [key: string]: FileType };
  _representative!: RepresentativeType;
  @Input() formRepresentative!: any;
  @Output() filesChange = new EventEmitter<Array<FileType>>();
  @Output() increaseRepresentative = new EventEmitter<void>();
  @Output() removeRepresentative = new EventEmitter<void>();

  constructor(
    private representativeTypeService: RepresentativeTypeService,
    private maritalStatusService: MaritalStatusService,
    private formService: FormService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._files = {};
    this._representative = {} as RepresentativeType;
    this.isAdministrator = false;
    this.representativeTypes = [];
    this.maritalStatuses = [];
  }

  ngOnInit(): void {
    this.representativeTypeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.representativeTypes = data;
      }
    });
    this.maritalStatusService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.maritalStatuses = data;
      }
    });

    this.type?.valueChanges.subscribe((type: number) => {
      if (Number(type) === 2) {
        this.letterOfAttorney.setValidators([Validators.required]);
      } else {
        this.letterOfAttorney.setValidators(null);
      }
      this.letterOfAttorney.updateValueAndValidity();
    });

    this.maritalStatus?.valueChanges.subscribe((maritalStatus: number) => {
      if (Number(maritalStatus) === 1) {
        this.spouseName.setValidators([Validators.required]);
        this.spouseCpf.setValidators([
          Validators.required,
          CustomValidator.cpfValidator
        ]);
      } else {
        this.spouseName.setValidators(null);
        this.spouseCpf.setValidators(null);
      }
      this.spouseName.updateValueAndValidity();
      this.spouseCpf.updateValueAndValidity();
    });
  }

  @Input() set representative(representative: RepresentativeType | undefined) {
    this._representative = representative || ({} as RepresentativeType);
    if (this._representative.files) {
      for (const file of this._representative.files) {
        this._files[file.type] = file;
      }
    }
  }

  @Input() set representativeNumber(representativeNumber: number) {
    this._representativeNumber = representativeNumber;
    if (this._representativeNumber === 0) {
      this.isAdministrator = true;
    }
  }

  @Input()
  set files(files: Array<FileType> | undefined) {
    if (files) {
      for (const file of files) {
        this._files[file.type] = file;
      }
    }
  }

  get name() {
    return this.formRepresentative.get('name');
  }

  get cpf() {
    return this.formRepresentative.get('cpf');
  }

  get type() {
    return this.formRepresentative.get('type');
  }

  get email() {
    return this.formRepresentative.get('email');
  }

  get maritalStatus() {
    return this.formRepresentative.get('maritalStatus');
  }

  get phone() {
    return this.formRepresentative.get('phone');
  }

  get spouseName() {
    return this.formRepresentative.get('spouseName');
  }

  get spouseCpf() {
    return this.formRepresentative.get('spouseCpf');
  }

  get proofResidence() {
    return this.formRepresentative.get('proofResidence');
  }

  get letterOfAttorney() {
    return this.formRepresentative.get('letterOfAttorney');
  }

  get isMarried() {
    return this.maritalStatus?.value === 1 || this.maritalStatus?.value === 2;
  }

  addRepresentative() {
    this.increaseRepresentative.emit();
  }

  excludeRepresentative() {
    this.removeRepresentative.emit();
  }

  updateFiles(files: { [key: string]: FileType }) {
    this._files = files;
    this.filesChange.emit(Object.values(this._files));
  }

  changeFile(event: any, type: string) {
    this.profileFileService
      .changeFileData(this._files, event, type, this.representativeId)
      .then((files: { [key: string]: FileType }) => {
        this.updateFiles(files);
        this.snackBar.open(defaultMessages.upload_success, 'Fechar', {
          panelClass: ['snack-success'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      })
      .catch(() => {
        this.snackBar.open(defaultMessages.upload_failed, 'Fechar', {
          panelClass: ['snack-error'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      });
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }
}
