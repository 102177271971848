import { Component, Input } from '@angular/core';
import { StaticDataType } from '../../../types/static-data.type';
import { MaritalStatusService } from '../../../services/static-data/marital-status.service';
import { RepresentativeTypeService } from '../../../services/static-data/representative-type.service';
import { FileType } from '../../../types/profile/file.type';
import { ProfileFileService } from '../../../services/profile/profile-file.service';
import { RepresentativeType } from '../../../types/profile/representative.type';
import { defaultMessages } from '../../../data/default-messages';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-representative-data-detail',
  templateUrl: './representative-data-detail.component.html',
  styleUrls: ['./representative-data-detail.component.scss']
})
export class RepresentativeDataDetailComponent {
  representativeType: StaticDataType;
  maritalStatus: StaticDataType;
  _files: { [key: string]: FileType };
  _representative: RepresentativeType;
  @Input() representativeNumber!: number;

  constructor(
    private representativeTypeService: RepresentativeTypeService,
    private maritalStatusService: MaritalStatusService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._files = {};
    this._representative = {} as RepresentativeType;
    this.representativeType = {} as StaticDataType;
    this.maritalStatus = {} as StaticDataType;
  }

  @Input() set representative(representative: RepresentativeType) {
    this._representative = representative;
    if (this._representative.files) {
      for (const file of this._representative.files) {
        this._files[file.type] = file;
      }
    }

    if (this._representative.cpf) {
      this.representativeTypeService.get(this._representative.type).subscribe({
        next: (data: StaticDataType) => {
          this.representativeType = data;
        }
      });
      this.maritalStatusService
        .get(this._representative.maritalStatus)
        .subscribe({
          next: (data: StaticDataType) => {
            this.maritalStatus = data;
          }
        });
    }
  }

  isMarried() {
    return this.maritalStatus.id === 1;
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }
}
