import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeclarationProfileType } from '../../../types/profile/declaration.type';

@Component({
  selector: 'app-profile-declaration-detail',
  templateUrl: './declaration-detail.component.html',
  styleUrls: ['./declaration-detail.component.scss']
})
export class DeclarationDetailComponent {
  @Input() declarationProfile: DeclarationProfileType;
  @Output() previousChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<void>();

  constructor() {
    this.declarationProfile = {} as DeclarationProfileType;
  }

  previous() {
    this.previousChange.emit();
  }

  edit() {
    this.editChange.emit();
  }
}
