<h2 class="mt-10 text-blue">Endereço</h2>
<form [formGroup]="form">
  <div>
    <div
      *ngIf="
        addressType?.invalid && (addressType?.dirty || addressType?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="addressType?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Tipo de endereço</mat-label>
      <mat-select
        formControlName="addressType"
        [(ngModel)]="contactData.addressType"
        required
      >
        <mat-option
          *ngFor="let addressType of addressTypes"
          [value]="addressType.id"
          >{{ addressType.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="[0, 1].includes(addressType?.value)">
    <h2 class="mt-10 mb-6 text-blue">Informações de Endereço</h2>
    <div *ngIf="addressType?.value === 1">
      <div
        *ngIf="country?.invalid && (country?.dirty || country?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="country?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>País</mat-label>
        <input
          formControlName="country"
          [(ngModel)]="contactData.country"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)"
        class="invalid-feedback"
      >
        <div
          *ngIf="zipCode?.errors?.['required'] || zipCode?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label *ngIf="addressType?.value === 0">CEP</mat-label>
        <mat-label *ngIf="addressType?.value === 1">Código postal</mat-label>
        <input
          mask="00.000-000"
          placeholder="xx.xxx-xxx"
          *ngIf="addressType?.value === 0"
          formControlName="zipCode"
          [(ngModel)]="contactData.zipCode"
          required
          matInput
        />
        <input
          *ngIf="addressType?.value === 1"
          formControlName="zipCode"
          [(ngModel)]="contactData.zipCode"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="address?.invalid && (address?.dirty || address?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="address?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Endereço</mat-label>
        <input
          formControlName="address"
          [(ngModel)]="contactData.address"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="
            addressNumber?.invalid &&
            (addressNumber?.dirty || addressNumber?.touched)
          "
          class="invalid-feedback"
        >
          <div type="number" *ngIf="addressNumber?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Número</mat-label>
          <input
            formControlName="addressNumber"
            [(ngModel)]="contactData.addressNumber"
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            addressComplement?.invalid &&
            (addressComplement?.dirty || addressComplement?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="addressComplement?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Complemento</mat-label>
          <input
            formControlName="addressComplement"
            [(ngModel)]="contactData.addressComplement"
            matInput
          />
        </mat-form-field>
      </div>
    </div>
    <div>
      <div
        *ngIf="
          neighborhood?.invalid &&
          (neighborhood?.dirty || neighborhood?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="neighborhood?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Bairro</mat-label>
        <input
          formControlName="neighborhood"
          [(ngModel)]="contactData.neighborhood"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="city?.invalid && (city?.dirty || city?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="city?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Cidade</mat-label>
          <input
            *ngIf="addressType?.value === 1"
            formControlName="city"
            [(ngModel)]="contactData.city"
            required
            matInput
          />
          <mat-select
            *ngIf="addressType?.value === 0"
            formControlName="city"
            [(ngModel)]="contactData.city"
            required
          >
            <mat-option value="one">Cidade 1</mat-option>
            <mat-option value="two">Cidade 2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            federatedState?.invalid &&
            (federatedState?.dirty || federatedState?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="federatedState?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>UF</mat-label>
          <input
            *ngIf="addressType?.value === 1"
            formControlName="federatedState"
            [(ngModel)]="contactData.federatedState"
            required
            matInput
          />
          <mat-select
            *ngIf="addressType?.value === 0"
            formControlName="federatedState"
            [(ngModel)]="contactData.federatedState"
            required
          >
            <mat-option value="one">Estado 1</mat-option>
            <mat-option value="two">Estado 2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <h2 class="mt-10 mb-6 text-blue">Informações de Contato</h2>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="email?.invalid && (email?.dirty || email?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="email?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="email?.errors?.['pattern']">
          {{ errorMessages.INVALID_EMAIL }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>E-mail de Contato</mat-label>
        <input
          formControlName="email"
          [(ngModel)]="contactData.email"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
        class="invalid-feedback"
      >
        <div
          *ngIf="phone?.errors?.['required'] || phone?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Telefone de Contato</mat-label>
        <input
          *ngIf="addressType?.value === 0 || !addressType?.value"
          formControlName="phone"
          [(ngModel)]="contactData.phone"
          required
          matInput
          placeholder="(xx) xxxx-xxxx"
          mask="(00) 0000-0000||(00) 0 0000-0000"
        />
        <input
          *ngIf="addressType?.value === 1"
          formControlName="phone"
          [(ngModel)]="contactData.phone"
          required
          matInput
        />
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="_estimatedValue === 1">
    <h2 class="mt-10 mb-6 text-blue">Local de nascimento</h2>
    <div>
      <div
        *ngIf="
          birthCountry?.invalid &&
          (birthCountry?.dirty || birthCountry?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="birthCountry?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>País</mat-label>
        <input
          formControlName="birthCountry"
          [(ngModel)]="contactData.birthCountry"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2">
      <div>
        <div
          *ngIf="
            birthState?.invalid && (birthState?.dirty || birthState?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="birthState?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Estado</mat-label>
          <input
            formControlName="birthState"
            [(ngModel)]="contactData.birthState"
            required
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="birthCity?.invalid && (birthCity?.dirty || birthCity?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="birthCity?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Cidade</mat-label>
          <input
            formControlName="birthCity"
            [(ngModel)]="contactData.birthCity"
            required
            matInput
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="next()">
      Prosseguir
    </button>
  </div>
</form>
