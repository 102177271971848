import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  LogLevel,
  RedirectRequest
} from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration
} from '@azure/msal-angular';

type Authorities = { [key: string]: RedirectRequest };

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
const baseUrlAuthority = `https://${environment.azureAD.tenantName}.b2clogin.com/${environment.azureAD.tenantName}.onmicrosoft.com`;

const authorities: Authorities = {
  signUpSignIn: {
    authority: `${baseUrlAuthority}/${environment.azureAD.userFlow.signUpSignIn}`,
    scopes: ['openid']
  },
  editProfile: {
    authority: `${baseUrlAuthority}/${environment.azureAD.userFlow.editProfile}`,
    scopes: ['openid']
  },
  resetPassword: {
    authority: `${baseUrlAuthority}/${environment.azureAD.userFlow.resetPassword}`,
    scopes: ['openid']
  }
};

export const b2cPolicies = {
  authorities: authorities,
  names: {
    signUpSignIn: environment.azureAD.userFlow.signUpSignIn,
    editProfile: environment.azureAD.userFlow.editProfile,
    resetPassword: environment.azureAD.userFlow.resetPassword
  },
  authorityDomain: `${environment.azureAD.tenantName}.b2clogin.com`
};

export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azureAD.clientId,
    authority: b2cPolicies.authorities['signUpSignIn'].authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: environment.azureAD.redirectUrl
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE
  },
  system: {
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Warning,
      piiLoggingEnabled: false
    }
  }
};

export const protectedResources: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['openid']
  },
  loginFailedRoute: '/autenticacao'
};

export const loginRequest = {
  scopes: ['openid']
};

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('message => ', message);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`https://google.com`, ['openid']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
