import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadersComponent } from './components/headers/headers.component';
import { NavbarComponent } from './components/headers/navbar/navbar.component';
import { MaterialModule } from './material.module';
import { DeclarationFormComponent } from './components/profile/declaration-form/declaration-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';

// Physical Person
import { BasicDataFormPfComponent } from './components/profile/physical-person/basic-data-form/basic-data-form-pf.component';
import { ContactFormPfComponent } from './components/profile/physical-person/contact-form/contact-form-pf.component';
import { DocsFormPfComponent } from './components/profile/physical-person/docs-form/docs-form-pf.component';

// Legal Person
import { ConstitutionDataFormPjComponent } from './components/profile/legal-person/constitution-data-form/constitution-data-form-pj.component';
import { DocsFormPjComponent } from './components/profile/legal-person/docs-form/docs-form-pj.component';
import { BasicDataFormPjComponent } from './components/profile/legal-person/basic-data-form/basic-data-form-pj.component';
import { PreRegisterComponent } from './components/profile/pre-register/pre-register.component';
import { RepresentativeDataFormComponent } from './components/profile/representative-data-form/representative-data-form.component';
import { RepresentativeDataDetailComponent } from './components/profile/representative-data-detail/representative-data-detail.component';
import { BasicDataDetailPfComponent } from './components/profile/physical-person/basic-data-detail/basic-data-detail-pf.component';
import { ContactDetailPfComponent } from './components/profile/physical-person/contact-detail/contact-detail-pf.component';
import { DocsDetailPfComponent } from './components/profile/physical-person/docs-detail/docs-detail-pf.component';
import { DeclarationDetailComponent } from './components/profile/declaration-detail/declaration-detail.component';
import { BasicDataDetailPjComponent } from './components/profile/legal-person/basic-data-detail/basic-data-detail-pj.component';
import { constitutionDataDetailPjComponent } from './components/profile/legal-person/constitution-data-detail/constitution-data-detail-pj.component';
import { DocsDetailPjComponent } from './components/profile/legal-person/docs-detail/docs-detail-pj.component';
import { PartnerDataFormComponent } from './components/profile/legal-person/partner-data-form/partner-data-form.component';
import { PartnerDataDetailComponent } from './components/profile/legal-person/partner-data-detail/partner-data-detail.component';

@NgModule({
  imports: [
    MaterialModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HeadersComponent,
    CurrencyMaskModule,
    PartnerDataDetailComponent,
    PartnerDataFormComponent,
    DeclarationFormComponent,
    DeclarationDetailComponent,
    BasicDataFormPfComponent,
    BasicDataDetailPjComponent,
    BasicDataDetailPfComponent,
    ContactFormPfComponent,
    ContactDetailPfComponent,
    constitutionDataDetailPjComponent,
    DocsFormPfComponent,
    DocsDetailPjComponent,
    DocsDetailPfComponent,
    ConstitutionDataFormPjComponent,
    RepresentativeDataDetailComponent,
    RepresentativeDataFormComponent,
    DocsFormPjComponent,
    BasicDataFormPjComponent,
    PreRegisterComponent
  ],
  providers: [],
  declarations: [
    HeadersComponent,
    NavbarComponent,
    PartnerDataDetailComponent,
    PartnerDataFormComponent,
    DeclarationFormComponent,
    DeclarationDetailComponent,
    BasicDataFormPfComponent,
    BasicDataDetailPjComponent,
    BasicDataDetailPfComponent,
    ContactFormPfComponent,
    ContactDetailPfComponent,
    constitutionDataDetailPjComponent,
    DocsFormPfComponent,
    DocsDetailPjComponent,
    ConstitutionDataFormPjComponent,
    RepresentativeDataDetailComponent,
    RepresentativeDataFormComponent,
    DocsFormPjComponent,
    DocsDetailPfComponent,
    BasicDataFormPjComponent,
    PreRegisterComponent
  ]
})
export class SharedModule {}
