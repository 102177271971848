<mat-nav-list class="justify-around px-4 pt-0 hidden lg:flex">
  <a
    mat-list-item
    [routerLink]="[routeItem.route]"
    routerLinkActive="active-link"
    *ngFor="let routeItem of availableRoutes"
  >
    <span class="flex items-center justify-center w-full">
      <mat-icon class="mr-2.5">
        {{ routeItem.icon }}
      </mat-icon>
      <p class="mb-0">{{ routeItem.title }}</p>
    </span>
  </a>
</mat-nav-list>
