import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PartnerType } from '../types/profile/legal-person.type';
import { CustomValidator } from '../custom.validator';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor() {}

  newPartnerData(partner: PartnerType): FormGroup {
    return new FormGroup({
      type: new FormControl(partner.type, [Validators.required]),
      documentNumber: new FormControl(partner.documentNumber, [
        Validators.required
      ]),
      name: new FormControl(partner.name, [Validators.required]),
      email: new FormControl(partner.email, [
        Validators.required,
        CustomValidator.emailValidator()
      ]),
      phone: new FormControl(partner.phone, [Validators.required]),
      shareholding: new FormControl(partner.shareholding, [
        Validators.min(0),
        Validators.max(100),
        Validators.required
      ]),
      asset: new FormControl(partner.asset),
      monthlyIncome: new FormControl(partner.monthlyIncome),
      constitutiveActs: new FormControl(null),
      invoicing: new FormControl(null),
      balance: new FormControl(null)
    });
  }
}
