import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends StaticDataService {
  constructor(private http: HttpClient) {
    super();
    this.data = [
      {
        id: 0,
        name: 'Nacional'
      },
      {
        id: 1,
        name: 'Internacional'
      }
    ];
  }

  getByCep(_cep: string): Observable<any> {
    return this.http.get(environment.apiCepUrl);
  }
}
