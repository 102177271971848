<h2 class="mt-10 text-blue">Documento Pessoal</h2>
<div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Tipo de Documento</mat-label>
    <p>{{ personalDocumentType.name }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Número do Documento</mat-label>
    <p>{{ _physicalPerson.personalDocumentNumber }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Órgão Expedidor</mat-label>
    <p>{{ _physicalPerson.personalDocumentIssuer }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Data de Emissão</mat-label>
    <p>{{ personalDocumentIssueDate }}</p>
  </div>
  <div class="grid grid-cols-2 gap-x-8">
    <div
      class="input-file-container mt-1 w-full"
      (click)="download('personalDocument')"
    >
      <label class="flex align-center justify-start">
        <mat-icon class="text-cyan">file_download</mat-icon>
        <span class="text-cyan">Baixar Arquivo</span>
      </label>
    </div>
  </div>
  <div *ngIf="_physicalPerson.estimatedOperationValue === 1">
    <h2 class="mt-10 mb-6 text-blue">Comprovante de Endereço*</h2>
    <div class="grid grid-cols-2 gap-x-8">
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('proofResidence')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
    <h2 class="mt-10 mb-6 text-blue">
      Declaração de CBE (Capital Brasileiro no Exterior)
    </h2>
    <div class="grid grid-cols-2 gap-x-8">
      <div class="input-file-container mt-1 w-full" (click)="download('cbe')">
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>

    <h2 class="mt-10 mb-6 text-blue">Comprovação de Renda</h2>
    <div>
      <mat-radio-group class="text-gray w-full gap-6 flex mb-5 px-1" required>
        <mat-radio-button
          [disabled]="true"
          [checked]="_physicalPerson.incomeProof === 0"
          value="0"
          >Imposto de Renda + Recibo</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('incomeTaxDeclaration')"
      >
        <span class="text-gray-light">Imposto de Renda - Declaração</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('incomeTaxReceipt')"
      >
        <span class="text-gray-light">Imposto de Renda - Recibo</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
    </div>
    <div class="mt-6">
      <mat-radio-group class="text-gray w-full gap-6 flex mb-5 px-1" required>
        <mat-radio-button
          [disabled]="true"
          [checked]="_physicalPerson.incomeProof === 1"
          value="1"
          >Holerite (últimos 3 meses)</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="grid grid-cols-3 gap-2 gap-x-8">
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('payslipMonth1')"
      >
        <span class="text-gray-light">Mês 1</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('payslipMonth2')"
      >
        <span class="text-gray-light">Mês 2</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('payslipMonth3')"
      >
        <span class="text-gray-light">Mês 3</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
    </div>
    <div class="mt-6">
      <mat-radio-group class="text-gray w-full gap-6 flex mb-5 px-1" required>
        <mat-radio-button
          [disabled]="true"
          [checked]="_physicalPerson.incomeProof === 2"
          value="2"
          >Extrato de Conta Corrente (últimos 3 meses)</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="grid grid-cols-3 gap-2 gap-x-8">
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('currentAccountStatementMonth1')"
      >
        <span class="text-gray-light">Mês 1</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('currentAccountStatementMonth2')"
      >
        <span class="text-gray-light">Mês 2</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('currentAccountStatementMonth2')"
      >
        <span class="text-gray-light">Mês 3</span>
        <label class="flex align-center justify-center">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan p-0 m-0">Baixar Arquivo</span>
        </label>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mt-10 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="edit()">
      Solcitar Edição de Cadastro
    </button>
  </div>
</div>
