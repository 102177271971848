import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RepresentativeType } from '../types/profile/representative.type';
import { CustomValidator } from '../custom.validator';

@Injectable({
  providedIn: 'root'
})
export class RepresentativeService {
  constructor() {}

  newRepresentativeData(representativeType: RepresentativeType): FormGroup {
    return new FormGroup({
      name: new FormControl(representativeType.name, [Validators.required]),
      cpf: new FormControl(representativeType.cpf, [
        Validators.required,
        CustomValidator.cpfValidator
      ]),
      type: new FormControl(representativeType.type, [Validators.required]),
      email: new FormControl(representativeType.email, [
        Validators.required,
        CustomValidator.emailValidator()
      ]),
      phone: new FormControl(representativeType.phone, [Validators.required]),
      maritalStatus: new FormControl(representativeType.maritalStatus, [
        Validators.required
      ]),
      spouseName: new FormControl(representativeType.spouseName),
      spouseCpf: new FormControl(representativeType.spouseCpf),
      proofResidence: new FormControl(null, [Validators.required]),
      letterOfAttorney: new FormControl(null, [])
    });
  }
}
