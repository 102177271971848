import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PhysicalPerson } from '../../../../types/profile/physical-person.type';
import { EstimatedOperationValueService } from '../../../../services/static-data/estimated-operation-value.service';
import { GenderService } from '../../../../services/static-data/gender.service';
import { MaritalStatusService } from '../../../../services/static-data/marital-status.service';
import { MonthlyIncomeService } from '../../../../services/static-data/monthly-income.service';
import { StaticDataType } from '../../../../types/static-data.type';
import * as moment from 'moment';

@Component({
  selector: 'app-basic-data-detail-pf',
  templateUrl: './basic-data-detail-pf.component.html',
  styleUrls: ['./basic-data-detail-pf.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicDataDetailPfComponent {
  _physicalPerson: PhysicalPerson;
  estimatedOperationValue: StaticDataType;
  gender: StaticDataType;
  maritalStatus: StaticDataType;
  monthlyIncome: StaticDataType;
  birthDate: string = '';
  @Output() editChange = new EventEmitter<void>();

  constructor(
    private estimatedOperationValueService: EstimatedOperationValueService,
    private genderService: GenderService,
    private maritalStatusService: MaritalStatusService,
    private monthlyIncomeService: MonthlyIncomeService
  ) {
    this._physicalPerson = {} as PhysicalPerson;
    this.estimatedOperationValue = {} as StaticDataType;
    this.gender = {} as StaticDataType;
    this.maritalStatus = {} as StaticDataType;
    this.monthlyIncome = {} as StaticDataType;
  }

  edit() {
    this.editChange.emit();
  }

  @Input() set physicalPerson(physicalPerson: PhysicalPerson) {
    this._physicalPerson = physicalPerson;
    this.birthDate = moment(new Date(this._physicalPerson.birthDate)).format(
      'DD/MM/YYYY'
    );
    if (this._physicalPerson.status !== undefined) {
      this.estimatedOperationValueService
        .get(this._physicalPerson.estimatedOperationValue)
        .subscribe({
          next: (estimatedOperationValue: StaticDataType) => {
            this.estimatedOperationValue = estimatedOperationValue;
          }
        });
      this.genderService.get(this._physicalPerson.gender).subscribe({
        next: (gender: StaticDataType) => {
          this.gender = gender;
        }
      });
      this.maritalStatusService
        .get(this._physicalPerson.maritalStatus)
        .subscribe({
          next: (maritalStatus: StaticDataType) => {
            this.maritalStatus = maritalStatus;
          }
        });
      this.monthlyIncomeService
        .get(this._physicalPerson.monthlyIncome)
        .subscribe({
          next: (monthlyIncome: StaticDataType) => {
            this.monthlyIncome = monthlyIncome;
          }
        });
    }
  }
}
