import { Component, Input } from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { NavigationType } from '../../../types/navigation.type';

@Component({
  selector: 'app-navbar-component',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() availableRoutes: Array<NavigationType> = [];

  constructor(private authService: AuthService) {}

  logout(): void {
    this.authService.logout();
  }
}
