import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorType } from '../../../../types/form-error.type';
import { defaultFormErros } from '../../../../enums/default-form-errors.enum';
import {
  ContinuationDataType,
  PartnerType
} from '../../../../types/profile/legal-person.type';
import { StaticDataType } from '../../../../types/static-data.type';
import { CompanyCapitalService } from '../../../../services/static-data/company-capital.service';
import { RepresentativeService } from '../../../../services/representative.service';
import { RepresentativeType } from '../../../../types/profile/representative.type';
import { PartnerService } from '../../../../services/partner.service';
import { FormService } from '../../../../services/form.service';
import { FileType } from '../../../../types/profile/file.type';

@Component({
  selector: 'app-constitution-data-form-pj',
  templateUrl: './constitution-data-form-pj.component.html',
  styleUrls: ['./constitution-data-form-pj.component.scss']
})
export class ConstitutionDataFormPjComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  companyCapitals: Array<StaticDataType>;
  _constitutionData: ContinuationDataType;
  _dynamicDataLoaded: boolean;
  @Output() constitutionDataChange = new EventEmitter<ContinuationDataType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() nextChange = new EventEmitter<void>();
  @Output() previousChange = new EventEmitter<void>();

  constructor(
    private companyCapitalService: CompanyCapitalService,
    private representativeService: RepresentativeService,
    private partnerService: PartnerService,
    private formService: FormService
  ) {
    this._dynamicDataLoaded = false;
    this.companyCapitals = [];
    this._constitutionData = {
      representatives: [{}],
      partners: [{}]
    } as ContinuationDataType;
    this.form = new FormGroup({
      cnae: new FormControl(this._constitutionData.cnae, [Validators.required]),
      numberEmployees: new FormControl(this._constitutionData.numberEmployees, [
        Validators.required
      ]),
      companyCapital: new FormControl(this._constitutionData.companyCapital, [
        Validators.required
      ]),
      bank: new FormControl(this._constitutionData.bank, [Validators.required]),
      bankAgency: new FormControl(this._constitutionData.bankAgency, [
        Validators.required
      ]),
      bankAccount: new FormControl(this._constitutionData.bankAccount, [
        Validators.required
      ]),
      representatives: new FormArray(
        [this.newRepresentativeData()],
        [Validators.required]
      ),
      partners: new FormArray([this.newPartnerData()], [Validators.required])
    });
    this._constitutionData = this.form.value;

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit() {
    this.companyCapitalService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.companyCapitals = data;
      }
    });
  }

  @Input() set constitutionData(constitutionData: ContinuationDataType) {
    this._constitutionData = constitutionData;

    if (this._constitutionData.cnae !== undefined && !this._dynamicDataLoaded) {
      this._dynamicDataLoaded = true;
      for (let i = 1; i < this._constitutionData.representatives.length; i++) {
        this.addRepresentativeData();
      }
      for (let i = 1; i < this._constitutionData.partners.length; i++) {
        this.addPartnerData();
      }
    }
  }

  get cnae() {
    return this.form.get('cnae');
  }

  get numberEmployees() {
    return this.form.get('numberEmployees')!;
  }

  get companyCapital() {
    return this.form.get('companyCapital')!;
  }

  get bank() {
    return this.form.get('bank')!;
  }

  get bankAgency() {
    return this.form.get('bankAgency')!;
  }

  get bankAccount() {
    return this.form.get('bankAccount')!;
  }

  representatives(): FormArray {
    return this.form?.get('representatives') as FormArray;
  }

  partners(): FormArray {
    return this.form.get('partners')! as FormArray;
  }

  updatedRepresentativeFiles(index: number, files: Array<FileType>) {
    this._constitutionData.representatives[index].files = files;
  }

  updatedPartnerFiles(index: number, files: Array<FileType>) {
    this._constitutionData.partners[index].files = files;
  }

  newRepresentativeData(): FormGroup {
    const type = this.representatives()?.controls?.length > 0 ? undefined : 0;
    const representative = {
      type: type
    } as RepresentativeType;
    return this.representativeService.newRepresentativeData(representative);
  }

  newPartnerData(): FormGroup {
    return this.partnerService.newPartnerData({} as PartnerType);
  }

  addRepresentativeData() {
    this.representatives().push(this.newRepresentativeData());
  }

  removeRepresentativeData(index: number) {
    this.representatives().removeAt(index);
  }

  addPartnerData() {
    this.partners().push(this.newPartnerData());
  }

  removePartnerData(index: number) {
    this.partners().removeAt(index);
  }

  updateData() {
    this._constitutionData = this.form.value;
    this.constitutionDataChange.emit(this._constitutionData);
    this.validChange.emit(this.form.valid);
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }

  previous() {
    this.previousChange.emit();
  }
}
