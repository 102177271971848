import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultFormErros } from 'src/app/shared/enums/default-form-errors.enum';
import { FormErrorType } from '../../../../types/form-error.type';
import { StaticDataType } from '../../../../types/static-data.type';
import { PersonalDocumentTypeService } from '../../../../services/static-data/personal-document-type.service';
import { FormService } from '../../../../services/form.service';
import { DocsPhysicalPersonType } from '../../../../types/profile/physical-person.type';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { FileType } from '../../../../types/profile/file.type';
import { profileFileAccepted } from '../../../../data/profile-files-accepted';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultMessages } from '../../../../data/default-messages';

@Component({
  selector: 'app-docs-form-pf',
  templateUrl: './docs-form-pf.component.html',
  styleUrls: ['./docs-form-pf.component.scss']
})
export class DocsFormPfComponent implements OnInit {
  form!: FormGroup;
  personalDocumentTypes: Array<StaticDataType>;
  errorMessages: FormErrorType = defaultFormErros;
  profileFileAccepted: string = profileFileAccepted;
  _files: { [key: string]: FileType };
  _estimatedValue!: number;
  _docsPhysicalPerson: DocsPhysicalPersonType;
  @Output() docsPhysicalPersonChange =
    new EventEmitter<DocsPhysicalPersonType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() previousChange = new EventEmitter<void>();
  @Output() nextChange = new EventEmitter<void>();

  constructor(
    private personalDocumentTypeService: PersonalDocumentTypeService,
    private formService: FormService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._files = {};
    this._docsPhysicalPerson = {} as DocsPhysicalPersonType;
    this.personalDocumentTypes = [];
    this.form = new FormGroup({
      personalDocumentType: new FormControl(
        this._docsPhysicalPerson.personalDocumentType,
        [Validators.required]
      ),
      personalDocumentNumber: new FormControl(
        this._docsPhysicalPerson.personalDocumentNumber,
        [Validators.required]
      ),
      personalDocumentIssuer: new FormControl(
        this._docsPhysicalPerson.personalDocumentIssuer,
        [Validators.required]
      ),
      personalDocumentIssueDate: new FormControl(
        this._docsPhysicalPerson.personalDocumentIssueDate,
        [Validators.required]
      ),
      personalDocument: new FormControl(this._files['personalDocument'], [
        Validators.required
      ]),
      incomeProof: new FormControl(this._docsPhysicalPerson.incomeProof, []),
      proofResidence: new FormControl(this._files['proofResidence'], []),
      cbe: new FormControl(this._files['cbe'], []),
      incomeTaxDeclaration: new FormControl(
        this._files['incomeTaxDeclaration'],
        []
      ),
      incomeTaxReceipt: new FormControl(this._files['incomeTaxReceipt'], []),
      payslipMonth1: new FormControl(this._files['payslipMonth1'], []),
      payslipMonth2: new FormControl(this._files['payslipMonth2'], []),
      payslipMonth3: new FormControl(this._files['payslipMonth3'], []),
      currentAccountStatementMonth1: new FormControl(
        this._files['currentAccountStatementMonth1'],
        []
      ),
      currentAccountStatementMonth2: new FormControl(
        this._files['currentAccountStatementMonth2'],
        []
      ),
      currentAccountStatementMonth3: new FormControl(
        this._files['currentAccountStatementMonth3'],
        []
      )
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit() {
    this.personalDocumentTypeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.personalDocumentTypes = data;
      }
    });

    this.incomeProof?.valueChanges.subscribe((incomeProof: number) => {
      if (Number(incomeProof) === 0) {
        this.incomeTaxDeclaration.setValidators([Validators.required]);
        this.incomeTaxReceipt.setValidators([Validators.required]);
        this.payslipMonth1.setValidators(null);
        this.payslipMonth2.setValidators(null);
        this.payslipMonth3.setValidators(null);
        this.currentAccountStatementMonth1.setValidators(null);
        this.currentAccountStatementMonth2.setValidators(null);
        this.currentAccountStatementMonth3.setValidators(null);
      } else if (Number(incomeProof) === 1) {
        this.incomeTaxDeclaration.setValidators(null);
        this.incomeTaxReceipt.setValidators(null);
        this.payslipMonth1.setValidators([Validators.required]);
        this.payslipMonth2.setValidators([Validators.required]);
        this.payslipMonth3.setValidators([Validators.required]);
        this.currentAccountStatementMonth1.setValidators(null);
        this.currentAccountStatementMonth2.setValidators(null);
        this.currentAccountStatementMonth3.setValidators(null);
      } else if (Number(incomeProof) === 2) {
        this.incomeTaxDeclaration.setValidators(null);
        this.incomeTaxReceipt.setValidators(null);
        this.payslipMonth1.setValidators(null);
        this.payslipMonth2.setValidators(null);
        this.payslipMonth3.setValidators(null);
        this.currentAccountStatementMonth1.setValidators([Validators.required]);
        this.currentAccountStatementMonth2.setValidators([Validators.required]);
        this.currentAccountStatementMonth3.setValidators([Validators.required]);
      } else {
        this.incomeTaxDeclaration.setValidators(null);
        this.incomeTaxReceipt.setValidators(null);
        this.payslipMonth1.setValidators(null);
        this.payslipMonth2.setValidators(null);
        this.payslipMonth3.setValidators(null);
        this.currentAccountStatementMonth1.setValidators(null);
        this.currentAccountStatementMonth2.setValidators(null);
        this.currentAccountStatementMonth3.setValidators(null);
      }
      this.proofResidence?.setValue(null);
      this.incomeTaxReceipt?.setValue(null);
      this.payslipMonth1?.setValue(null);
      this.payslipMonth2?.setValue(null);
      this.payslipMonth3?.setValue(null);
      this.currentAccountStatementMonth1?.setValue(null);
      this.currentAccountStatementMonth2?.setValue(null);
      this.currentAccountStatementMonth3?.setValue(null);
      this.proofResidence.updateValueAndValidity();
      this.incomeTaxReceipt.updateValueAndValidity();
      this.payslipMonth1.updateValueAndValidity();
      this.payslipMonth2.updateValueAndValidity();
      this.payslipMonth3.updateValueAndValidity();
      this.currentAccountStatementMonth1.updateValueAndValidity();
      this.currentAccountStatementMonth2.updateValueAndValidity();
      this.currentAccountStatementMonth3.updateValueAndValidity();
    });
  }

  @Input() set docsPhysicalPerson(docsPhysicalPerson: DocsPhysicalPersonType) {
    this._docsPhysicalPerson = docsPhysicalPerson;
    if (this._docsPhysicalPerson.files) {
      for (const file of this._docsPhysicalPerson.files) {
        this._files[file.type] = file;
      }
    }
  }

  @Input()
  set estimatedValue(estimatedValue: number) {
    this._estimatedValue = Number(estimatedValue);

    if (this._estimatedValue === 0) {
      this.proofResidence.setValidators(null);
      this.incomeProof.setValidators(null);
      this.incomeProof.setValue(undefined);
    } else if (this._estimatedValue === 1) {
      this.proofResidence.setValidators([Validators.required]);
      this.incomeProof.setValidators([Validators.required]);
      this.incomeProof.setValue(0);
    }
    this.proofResidence.updateValueAndValidity();
    this.incomeProof.updateValueAndValidity();
  }

  updateData() {
    this._docsPhysicalPerson = this.form.value;
    this.docsPhysicalPersonChange.emit(this._docsPhysicalPerson);
    this.validChange.emit(this.form.valid);
  }

  updateFiles(files: { [key: string]: FileType }) {
    this._files = files;
    this._docsPhysicalPerson.files = Object.values(this._files);
    this.docsPhysicalPersonChange.emit(this._docsPhysicalPerson);
  }

  get personalDocumentType() {
    return this.form.get('personalDocumentType')!;
  }

  get personalDocumentNumber() {
    return this.form.get('personalDocumentNumber')!;
  }

  get cbe() {
    return this.form.get('cbe')!;
  }

  get personalDocumentIssuer() {
    return this.form.get('personalDocumentIssuer')!;
  }

  get personalDocumentIssueDate() {
    return this.form.get('personalDocumentIssueDate')!;
  }

  get personalDocument() {
    return this.form.get('personalDocument')!;
  }

  get proofResidence() {
    return this.form.get('proofResidence')!;
  }

  get incomeProof() {
    return this.form.get('incomeProof')!;
  }

  get incomeTaxDeclaration() {
    return this.form.get('incomeTaxDeclaration')!;
  }

  get incomeTaxReceipt() {
    return this.form.get('incomeTaxReceipt')!;
  }

  get payslipMonth1() {
    return this.form.get('payslipMonth1')!;
  }

  get payslipMonth2() {
    return this.form.get('payslipMonth2')!;
  }

  get payslipMonth3() {
    return this.form.get('payslipMonth3')!;
  }

  get currentAccountStatementMonth1() {
    return this.form.get('currentAccountStatementMonth1')!;
  }

  get currentAccountStatementMonth2() {
    return this.form.get('currentAccountStatementMonth2')!;
  }

  get currentAccountStatementMonth3() {
    return this.form.get('currentAccountStatementMonth3')!;
  }

  changeFile(event: any, type: string) {
    this.profileFileService
      .changeFileData(this._files, event, type)
      .then((files: { [key: string]: FileType }) => {
        this.updateFiles(files);
        if (event.target.files[0]) {
          this.snackBar.open(defaultMessages.upload_success, 'Fechar', {
            panelClass: ['snack-success'],
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5 * 1000
          });
        }
      })
      .catch(() => {
        this.snackBar.open(defaultMessages.upload_failed, 'Fechar', {
          panelClass: ['snack-error'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      });
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }

  previous() {
    this.previousChange.emit();
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }
}
