<div [formGroup]="formPartner">
  <h3 class="mt-10 text-blue">Sócio {{ (partnerNumber || 0) + 1 }}</h3>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="type?.invalid && (type?.dirty || type?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="type?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>CPF ou CNPJ</mat-label>
        <mat-select formControlName="type" [(ngModel)]="_partner.type" required>
          <mat-option
            value="{{ option.id }}"
            *ngFor="let option of partnerTypes"
            >{{ option.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          documentNumber?.invalid &&
          (documentNumber?.dirty || documentNumber?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="documentNumber?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="documentNumber?.errors?.['cpfInvalid']">
          {{ errorMessages.INVALID_CPF }}
        </div>
        <div *ngIf="documentNumber?.errors?.['cnpjInvalid']">
          {{ errorMessages.INVALID_CNPJ }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>{{ type?.value === 0 ? 'CPF' : 'CNPJ' }}</mat-label>
        <input
          formControlName="documentNumber"
          [(ngModel)]="_partner.documentNumber"
          required
          matInput
          [mask]="type?.value === 0 ? '000.000.000-00' : '00.000.000/0000-00'"
          (placeholder)="
            (type?.value === 0 ? 'xxx.xxx.xxx-xx' : 'xx.xxx.xxx/xxxx-xx')
          "
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div
      *ngIf="name?.invalid && (name?.dirty || name?.touched)"
      class="invalid-feedback pb-px"
    >
      <div *ngIf="name?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Nome do Sócio</mat-label>
      <input
        formControlName="name"
        [(ngModel)]="_partner.name"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="email?.invalid && (email?.dirty || email?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="email?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="email?.errors?.['pattern']">
          {{ errorMessages.INVALID_EMAIL }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Email de Contato</mat-label>
        <input
          formControlName="email"
          [(ngModel)]="_partner.email"
          required
          matInput
          type="email"
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
        class="invalid-feedback"
      >
        <div
          *ngIf="phone?.errors?.['required'] || phone?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Telefone de Contato</mat-label>
        <input
          formControlName="phone"
          [(ngModel)]="_partner.phone"
          required
          matInput
          mask="(00) 0000-0000||(00) 0 0000-0000"
          placeholder="(xx) xxxx-xxxx"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="
          shareholding?.invalid &&
          (shareholding?.dirty || shareholding?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="shareholding?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size percent">
        <mat-label>Participação</mat-label>
        <input
          formControlName="shareholding"
          [(ngModel)]="_partner.shareholding"
          required
          matInput
          type="text"
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="asset?.invalid && (asset?.dirty || asset?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="asset?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Patrimônio</mat-label>
        <input
          formControlName="asset"
          [(ngModel)]="_partner.asset"
          currencyMask
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
          required
          matInput
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div>
    <div
      *ngIf="
        monthlyIncome?.invalid &&
        (monthlyIncome?.dirty || monthlyIncome?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="monthlyIncome?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Renda Média Mensal</mat-label>
      <input
        formControlName="monthlyIncome"
        [(ngModel)]="_partner.monthlyIncome"
        currencyMask
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }"
        required
        matInput
        type="number"
      />
    </mat-form-field>
  </div>

  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <div>
      <div
        *ngIf="balance?.invalid && (balance?.dirty || balance?.touched)"
        class="invalid-feedback mt-1.5"
      >
        <div *ngIf="balance?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-label class="text-sm text-gray"
        >Balanço{{ shareholding?.value > 5 ? '*' : '' }}</mat-label
      >
      <div class="input-file-container mt-1 w-full">
        <input
          type="file"
          name="balance"
          [id]="'balance' + partnerNumber"
          formControlName="balance"
          (change)="changeFile($event, 'balance')"
          [accept]="profileFileAccepted"
          [required]="shareholding?.value > 5"
        />
        <label
          [htmlFor]="'balance' + partnerNumber"
          class="flex align-center justify-start"
        >
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{ _files['balance'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('balance')"
        *ngIf="_files['balance']"
        >Baixar Arquivo Anexado</span
      >
    </div>
    <div>
      <div
        *ngIf="invoicing?.invalid && (invoicing?.dirty || invoicing?.touched)"
        class="invalid-feedback mt-1.5"
      >
        <div *ngIf="invoicing?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-label class="text-sm text-gray"
        >Faturamento{{ shareholding?.value > 5 ? '*' : '' }}</mat-label
      >
      <div class="input-file-container mt-1 w-full">
        <input
          type="file"
          name="invoicing"
          [id]="'invoicing' + partnerNumber"
          (change)="changeFile($event, 'invoicing')"
          [accept]="profileFileAccepted"
          formControlName="invoicing"
          [required]="shareholding?.value > 5"
        />
        <label
          [htmlFor]="'invoicing' + partnerNumber"
          class="flex align-center justify-start"
        >
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{ _files['invoicing'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('invoicing')"
        *ngIf="_files['invoicing']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>

  <div class="grid grid-cols-2 mb-8 gap-x-8">
    <div>
      <div
        *ngIf="
          constitutiveActs?.invalid &&
          (constitutiveActs?.dirty || constitutiveActs?.touched)
        "
        class="invalid-feedback mt-1.5"
      >
        <div *ngIf="constitutiveActs?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-label class="text-sm text-gray"
        >Atos Constitutivos{{ shareholding?.value > 5 ? '*' : '' }}</mat-label
      >
      <div class="input-file-container mt-1 w-full">
        <input
          type="file"
          name="constitutiveActs"
          [id]="'constitutiveActs' + partnerNumber"
          (change)="changeFile($event, 'constitutiveActs')"
          [accept]="profileFileAccepted"
          formControlName="constitutiveActs"
          [required]="shareholding?.value > 5"
        />
        <label
          [htmlFor]="'constitutiveActs' + partnerNumber"
          class="flex align-center justify-start"
        >
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{
              _files['constitutiveActs'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
            }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('constitutiveActs')"
        *ngIf="_files['constitutiveActs']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>

  <div class="grid grid-cols-2 gap-2 mb-8">
    <button
      type="button"
      class="bg-inherit text-cyan flex gap-2 mb-4"
      (click)="addPartner()"
    >
      <mat-icon>add_box</mat-icon>
      <p>Adicionar Sócio</p>
    </button>

    <button
      type="button"
      class="bg-inherit text-error flex gap-2 mb-4"
      (click)="excludePartner()"
      *ngIf="partnerNumber > 0"
    >
      <mat-icon>delete</mat-icon>
      <p>Remover Sócio</p>
    </button>
  </div>
</div>
