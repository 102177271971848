<div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Endereço</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Tipo de Endereço</mat-label>
      <p>{{ addressType.name }}</p>
    </div>
    <div
      *ngIf="_physicalPerson.addressType === 1"
      class="grid grid-cols-2 gap-2 my-8 data-item"
    >
      <mat-label>País</mat-label>
      <p>{{ _physicalPerson.country }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CEP</mat-label>
      <p *ngIf="addressType.id === 0">
        {{ _physicalPerson.zipCode | mask: '00.000-000' }}
      </p>
      <p *ngIf="addressType.id === 1">{{ _physicalPerson.zipCode }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Endereço</mat-label>
      <p>{{ _physicalPerson.address }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Número</mat-label>
      <p>{{ _physicalPerson.addressNumber }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Complemento</mat-label>
      <p>{{ _physicalPerson.addressComplement }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Bairro</mat-label>
      <p>{{ _physicalPerson.neighborhood }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Cidade</mat-label>
      <p>{{ _physicalPerson.city }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>UF</mat-label>
      <p>{{ _physicalPerson.federatedState }}</p>
    </div>
  </div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Informações de Contato</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>E-mail de contato</mat-label>
      <p>{{ _physicalPerson.email }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Telefone de Contato</mat-label>
      <p>
        {{ _physicalPerson.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }}
      </p>
    </div>
  </div>
  <div *ngIf="_physicalPerson.estimatedOperationValue === 1">
    <h2 class="mt-10 mb-6 text-blue">Informações de Contato</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Pais</mat-label>
      <p>{{ _physicalPerson.country }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Estado</mat-label>
      <p>{{ _physicalPerson.birthCity }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Cidade</mat-label>
      <p>{{ _physicalPerson.birthState }}</p>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="edit()">
      Solcitar Edição de Cadastro
    </button>
  </div>
</div>
