import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class EstimatedOperationValueService extends StaticDataService {
  constructor() {
    super();
    this.data = [
      {
        id: 0,
        name: 'Até USD 10.000,00 ou equivalente'
      },
      {
        id: 1,
        name: 'Acima de USD 10.000,00'
      }
    ];
  }
}
