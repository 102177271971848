<h2 class="mt-10 mb-6 text-blue">Envio de Documentos</h2>
<div>
  <div class="grid grid-cols-3 gap-8">
    <div *ngIf="_legalPerson.documentType === 0">
      <div class="my-8">
        <mat-radio-group>
          <mat-radio-button
            [value]="0"
            [checked]="_legalPerson.documentType === 0"
            [disabled]="true"
            >Contrato Social</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="_legalPerson.documentType === 0">
        <mat-label class="text-sm text-gray">Contrato Social</mat-label>
        <div
          class="input-file-container mt-1 w-full"
          (click)="download('socialContract')"
        >
          <label class="flex align-center justify-start">
            <mat-icon class="check-icon text-cyan">file_download</mat-icon>
            <p class="text-cyan">Baixar Arquivo</p>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="_legalPerson.documentType === 1">
      <div class="my-8">
        <mat-radio-group>
          <mat-radio-button
            [value]="1"
            [checked]="_legalPerson.documentType === 1"
            >Estatuto Social</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="_legalPerson.documentType === 1">
        <mat-label class="text-sm text-gray">Estatuto Social</mat-label>
        <div class="input-file-container mt-1" (click)="download('bylaws')">
          <label class="flex align-center justify-start">
            <mat-icon class="check-icon text-cyan">file_download</mat-icon>
            <p class="text-cyan">Baixar Arquivo</p>
          </label>
        </div>
      </div>
    </div>
    <div *ngIf="_legalPerson.documentType === 2">
      <div class="my-8">
        <mat-radio-group>
          <mat-radio-button
            [value]="2"
            [checked]="_legalPerson.documentType === 2"
            >Ata</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="_legalPerson.documentType === 2">
        <mat-label class="text-sm text-gray">Ata</mat-label>
        <div class="input-file-container mt-1" (click)="download('ata')">
          <label class="flex align-center justify-start">
            <mat-icon class="check-icon text-cyan">file_download</mat-icon>
            <p class="text-cyan">Baixar Arquivo</p>
          </label>
        </div>
      </div>
    </div>
  </div>

  <h2 class="mt-10 mb-6 text-blue">Documentos Complementares</h2>

  <div class="grid grid-cols-2 gap-8 my-5">
    <div>
      <mat-label class="text-sm text-gray">Comprovante de Endereço</mat-label>
      <div
        class="input-file-container mt-1"
        (click)="download('proofResidence')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
    <div>
      <mat-label class="text-sm text-gray">Balanço</mat-label>
      <div class="input-file-container mt-1" (click)="download('balance')">
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-8 my-5">
    <div>
      <mat-label class="text-sm text-gray"
        >Faturamentos Últimos 12 meses</mat-label
      >
      <div
        class="input-file-container mt-1"
        (click)="download('billingsLastTwelveMonths')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
    <div *ngIf="_files['defis']">
      <mat-label class="text-sm text-gray"
        >Último DEFIS encerrado em 31/12</mat-label
      >
      <div class="input-file-container mt-1" (click)="download('defis')">
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-2 gap-8 my-5">
    <div *ngIf="_files['deliveryReceipt']">
      <mat-label class="text-sm text-gray">Comprovante de Entrega</mat-label>
      <div
        class="input-file-container mt-1"
        (click)="download('deliveryReceipt')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
    <div *ngIf="_files['pgdas']">
      <mat-label class="text-sm text-gray">Extrato PGDAS</mat-label>
      <div class="input-file-container mt-1" (click)="download('pgdas')">
        <label class="flex align-center justify-start">
          <mat-icon class="check-icon text-cyan">file_download</mat-icon>
          <p class="text-cyan">Baixar Arquivo</p>
        </label>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="edit()">
      Solcitar Edição de Cadastro
    </button>
  </div>
</div>
