import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FileType } from '../../types/profile/file.type';

@Injectable({
  providedIn: 'root'
})
export class ProfileFileService {
  constructor(private http: HttpClient) {}

  changeFileData(
    files: { [key: string]: FileType },
    event: any,
    type: string,
    uuid: string = ''
  ): Promise<{ [key: string]: FileType }> {
    return new Promise((resolve, reject) => {
      const file: File = event.target.files[0];
      if (file) {
        const extension = file.name.split('.').pop();
        const prefixName = uuid !== '' ? uuid + '_' : '';

        const fileType: FileType = {
          type: type,
          fileName: `${prefixName}${type}.${extension}`
        };
        this.upload(fileType, file).subscribe({
          next: (fileInfo: FileType) => {
            files[type] = fileInfo;
            resolve(files);
            return;
          },
          error: (error) => {
            reject(error);
            return;
          }
        });
      } else {
        delete files[type];
        resolve(files);
        return;
      }
    });
  }

  upload(fileInfo: FileType, file: File): Observable<any> {
    fileInfo.fileKey = '';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', fileInfo.type);
    formData.append('fileName', fileInfo.fileName);

    return this.http.post<FileType>(`${environment.apiUrl}/file`, formData);
  }

  downloadTemp(fileKey: string): Observable<File> {
    fileKey = 'dGVz';
    return this.http
      .get<File>(`${environment.apiUrl}/file/temp/${fileKey}`, {
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((data: File) => {
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          return data;
        })
      );
  }

  download(fileKey: string): Observable<File> {
    fileKey = 'dGVz';
    return this.http
      .get<File>(`${environment.apiUrl}/file/${fileKey}`, {
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((data: File) => {
          const blob = new Blob([data]);
          const url = window.URL.createObjectURL(blob);
          window.open(url);
          return data;
        })
      );
  }
}
