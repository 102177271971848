<div [formGroup]="formRepresentative">
  <h3 class="mt-10 text-blue">
    Representante {{ (_representativeNumber || 0) + 1 }}
  </h3>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="type?.invalid && (type?.dirty || type?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="type?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Tipo</mat-label>
        <mat-select
          formControlName="type"
          [(ngModel)]="_representative.type"
          [disabled]="isAdministrator"
          required
        >
          <mat-option
            *ngFor="let representativeType of representativeTypes"
            [value]="representativeType.id"
            >{{ representativeType.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="cpf?.invalid && (cpf?.dirty || cpf?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="cpf?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="cpf?.errors?.['cpfInvalid']">
          {{ errorMessages.INVALID_CPF }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>CPF</mat-label>
        <input
          formControlName="cpf"
          [(ngModel)]="_representative.cpf"
          required
          matInput
          mask="000.000.000-00"
          placeholder="xxx.xxx.xxx-xx"
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div
      *ngIf="name?.invalid && (name?.dirty || name?.touched)"
      class="invalid-feedback pb-px"
    >
      <div *ngIf="name?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Nome do Responsável</mat-label>
      <input
        formControlName="name"
        [(ngModel)]="_representative.name"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="email?.invalid && (email?.dirty || email?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="email?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="email?.errors?.['pattern']">
          {{ errorMessages.INVALID_EMAIL }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Email de Contato</mat-label>
        <input
          formControlName="email"
          [(ngModel)]="_representative.email"
          required
          matInput
          type="email"
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="phone?.invalid && (phone?.dirty || phone?.touched)"
        class="invalid-feedback"
      >
        <div
          *ngIf="phone?.errors?.['required'] || phone?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Telefone de Contato</mat-label>
        <input
          formControlName="phone"
          [(ngModel)]="_representative.phone"
          required
          matInput
          placeholder="(xx) xxxx-xxxx"
          mask="(00) 0000-0000||(00) 0 0000-0000"
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div
      *ngIf="
        maritalStatus?.invalid &&
        (maritalStatus?.dirty || maritalStatus?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="maritalStatus?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Estado Civil</mat-label>
      <mat-select
        formControlName="maritalStatus"
        [(ngModel)]="_representative.maritalStatus"
        required
      >
        <mat-option
          *ngFor="let maritalStatus of maritalStatuses"
          [value]="maritalStatus.id"
          >{{ maritalStatus.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isMarried">
    <h4 class="mt-6 matrial-subTitle">Dados do Cônjuge</h4>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="spouseCpf?.invalid && (spouseCpf?.dirty || spouseCpf?.touched)"
          class="invalid-feedback"
        >
          <div
            *ngIf="spouseCpf?.errors?.['required'] || spouseCpf?.errors?.['mask']?.['requiredMask']"
          >
            {{ errorMessages.REQUIRED }}
          </div>
          <div *ngIf="spouseCpf?.errors?.['cpfInvalid']">
            {{ errorMessages.INVALID_CPF }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>CPF</mat-label>
          <input
            formControlName="spouseCpf"
            [(ngModel)]="_representative.spouseCpf"
            [required]="isMarried"
            matInput
            mask="000.000.000-00"
            placeholder="xxx.xxx.xxx-xx"
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            spouseName?.invalid && (spouseName?.dirty || spouseName?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="spouseName?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Nome do Cônjuge</mat-label>
          <input
            formControlName="spouseName"
            [(ngModel)]="_representative.spouseName"
            [required]="isMarried"
            matInput
            type="spouseName"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <div class="w-full">
      <div
        *ngIf="
          proofResidence?.invalid &&
          (proofResidence?.dirty || proofResidence?.touched)
        "
        class="invalid-feedback mt-1.5"
      >
        <div *ngIf="proofResidence?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-label class="text-sm text-gray">Comprovante de Endereço*</mat-label>
      <div class="input-file-container mt-1 w-full">
        <input
          type="file"
          name="proofResidence"
          [id]="'proofResidence' + _representativeNumber"
          formControlName="proofResidence"
          (change)="changeFile($event, 'proofResidence')"
          [accept]="profileFileAccepted"
          required
        />
        <label
          [htmlFor]="'proofResidence' + _representativeNumber"
          class="flex align-center justify-start"
        >
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{
              _files['proofResidence'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
            }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('proofResidence')"
        *ngIf="_files['proofResidence']"
        >Baixar Arquivo Anexado</span
      >
    </div>
    <div class="w-full">
      <div
        *ngIf="
          letterOfAttorney?.invalid &&
          (letterOfAttorney?.dirty || letterOfAttorney?.touched)
        "
        class="invalid-feedback mt-1.5"
      >
        <div *ngIf="letterOfAttorney?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-label class="text-sm text-gray"
        >Procuração{{ type?.value === 2 ? '*' : '' }}</mat-label
      >
      <div class="input-file-container mt-1 w-full">
        <input
          type="file"
          name="letterOfAttorney"
          [id]="'letterOfAttorney' + _representativeNumber"
          (change)="changeFile($event, 'letterOfAttorney')"
          [accept]="profileFileAccepted"
          [required]="type?.value === 2"
          formControlName="letterOfAttorney"
        />
        <label
          [htmlFor]="'letterOfAttorney' + _representativeNumber"
          class="flex align-center justify-start"
        >
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{
              _files['letterOfAttorney'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
            }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('letterOfAttorney')"
        *ngIf="_files['letterOfAttorney']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>

  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <button
      type="button"
      class="bg-inherit text-cyan flex gap-2 mb-4"
      (click)="addRepresentative()"
    >
      <mat-icon>add_box</mat-icon>
      <p>Adicionar Representante Legal</p>
    </button>

    <button
      type="button"
      class="bg-inherit text-error flex gap-2 mb-4"
      (click)="excludeRepresentative()"
      *ngIf="_representativeNumber > 0"
    >
      <mat-icon>delete</mat-icon>
      <p>Remover Representante</p>
    </button>
  </div>
</div>
