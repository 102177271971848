import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {}

  toucheControls(form: FormGroup) {
    for (const control of Object.keys(form.controls)) {
      if (form.controls[control] instanceof FormArray) {
        const subControls = form.controls[control] as FormArray;
        for (const subControl of Object.keys(subControls.controls)) {
          this.toucheControls(subControls.get(subControl) as FormGroup);
        }
      } else {
        form.controls[control].markAsTouched();
      }
    }
  }
}
