<h2 class="mt-10 text-blue">Declaração de Política de Privacidade*</h2>
<form [formGroup]="form">
  <h2 class="mt-10 text-blue">Declarações US Person</h2>
  <div class="mt-6">
    <div
      *ngIf="
        privacyPoliceAccepted?.invalid &&
        (privacyPoliceAccepted?.dirty || privacyPoliceAccepted?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="privacyPoliceAccepted?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-checkbox
      formControlName="privacyPoliceAccepted"
      [(ngModel)]="declarationData.privacyPoliceAccepted"
      required
      color="primary"
    >
      <span color="cyan" class="text-base text-gray">
        Declaração de
        <a href="http://" class="text-cyan font-medium"
          >Políticas de Privacidade do Banco Semear S.A.</a
        >
      </span>
    </mat-checkbox>
  </div>
  <div class="mt-6">
    <ol class="list-decimal text-sm text-gray">
      <li class="mt-1">1. Nascido nos EUA ou em seus territórios;</li>
      <li class="mt-1">
        2. Possuir residência permanente nos EUA legalmente (Green Card);
      </li>
      <li class="mt-1">
        3. Deter cidadania americana ou ser residente fiscal nos EUA (residente
        estrangeiro);
      </li>
      <li class="mt-1">
        4. Ser filho de pelo menos um cidadão americano ou ter pais
        naturalizados;
      </li>
      <li class="mt-1">
        5. Ter presença substancial nos EUA (183 dias durante o triênio);
      </li>
      <li class="mt-1">
        6. Empresa constituída sob as leis dos EUA (Domestic Partnership ou
        sociedade doméstica).
      </li>
    </ol>
    <mat-checkbox
      formControlName="isUsPerson"
      [(ngModel)]="declarationData.isUsPerson"
      class="mt-6"
      color="primary"
    >
      <span color="cyan" class="text-base text-gray"
        >Declaração de <span class="font-medium">US Person</span>
      </span>
    </mat-checkbox>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de Pessoa Exposta Politicamente (PEP)
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Conforme estabelece a Circular no 3.978 do Banco Central do Brasil, de 23
      de janeiro de 2020, as instituições financeiras devem obter de seus
      clientes permanentes informações que permitam caracterizá-los ou não como
      Pessoas Expostas Politicamente (PEP).
    </p>
    <p>
      Consideram-se PEP os agentes públicos que desempenham ou tenham
      desempenhado, nos últimos 5 (cinco) anos, no Brasil ou em países,
      territórios e dependências strangeiros, cargos, empregos ou funções
      públicas relevantes, assim como seus representantes, familiares e outras
      pessoas de seu relacionamento próximo.
    </p>
    <mat-checkbox
      formControlName="isPoliticallyExposed"
      [(ngModel)]="declarationData.isPoliticallyExposed"
      class="mt-6"
      color="primary"
    >
      <span color="cyan" class="text-base text-gray">
        Declaração de
        <span class="font-medium">Pessoa Exposta Politicamente (PEP)</span>
      </span>
    </mat-checkbox>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de relacionamento/ligação com Agente Público
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Possui ou possuía relacionamento/ligação com Agente Público nos últimos 5
      (cinco) anos.
    </p>
    <mat-checkbox
      formControlName="isPublicAgentRelated"
      [(ngModel)]="declarationData.isPublicAgentRelated"
      class="mt-2 mb-6"
      color="primary"
    >
      <span color="cyan" class="text-base text-gray"
        >Declaração de
        <span class="font-medium"
          >relacionamento/ligação com Agente Público</span
        >
      </span>
    </mat-checkbox>
    <div class="grid grid-cols-3 gap-2">
      <div>
        <div
          *ngIf="
            publicAgentName?.invalid &&
            (publicAgentName?.dirty || publicAgentName?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="publicAgentName?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Nome completo do relacionado</mat-label>
          <input
            formControlName="publicAgentName"
            [(ngModel)]="declarationData.publicAgentName"
            [required]="isPublicAgentRelated?.value"
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            isPublicAgentRelated?.invalid &&
            (isPublicAgentRelated?.dirty || isPublicAgentRelated?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="isPublicAgentRelated?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Cargo ou Função</mat-label>
          <input
            formControlName="publicAgentPosition"
            [(ngModel)]="declarationData.publicAgentPosition"
            [required]="isPublicAgentRelated?.value"
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            publicAgentRelationshipType?.invalid &&
            (publicAgentRelationshipType?.dirty ||
              publicAgentRelationshipType?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="publicAgentRelationshipType?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Tipo de Relacionamento/Ligação</mat-label>
          <input
            formControlName="publicAgentRelationshipType"
            [(ngModel)]="declarationData.publicAgentRelationshipType"
            [required]="isPublicAgentRelated?.value"
            matInput
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de atividade(s) que relacionada(s) ao Risco Socioambiental
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Seguindo as diretrizes da Resolução no 4.327 do Conselho Monetário
      Nacional, de 25 de abril de 2014, o CLIENTE, declara, à Instituição
      Financeira, para todos os fins legais, que exerce alguma(s) atividade(s)
      que esteja(m) relacionada(s) ao Risco Socioambiental constante no Termo de
      Compromisso e Responsabilidade Socioambiental do Banco Semear S.A.
    </p>
    <mat-checkbox
      formControlName="isSocialEnvironmentalRisk"
      [(ngModel)]="declarationData.isSocialEnvironmentalRisk"
      class="mt-2 mb-6"
      color="primary"
    >
      <span color="cyan" class="text-base text-gray">
        Declaração de
        <span class="font-medium"
          >atividade(s) que relacionada(s) ao Risco Socioambiental</span
        >
      </span>
    </mat-checkbox>
    <div class="grid grid-cols-1 gap-2">
      <div
        *ngIf="
          environmentalRiskActivities?.invalid &&
          (environmentalRiskActivities?.dirty ||
            environmentalRiskActivities?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="environmentalRiskActivities?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="fluid">
        <mat-label
          >Em caso positivo, especifique aqui a(s) atividade(s)</mat-label
        >
        <input
          formControlName="environmentalRiskActivities"
          [(ngModel)]="declarationData.environmentalRiskActivities"
          [required]="isSocialEnvironmentalRisk?.value"
          matInput
        />
      </mat-form-field>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mt-10 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button
      mat-button
      class="w-full bg-cyan text-neutral-100"
      (click)="submit()"
    >
      Finalizar Cadastro
    </button>
  </div>
</form>
