import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { LegalPerson } from '../../../../types/profile/legal-person.type';
import { AddressService } from '../../../../services/static-data/address.service';
import { StaticDataType } from '../../../../types/static-data.type';

@Component({
  selector: 'app-basic-data-detail-pj',
  templateUrl: './basic-data-detail-pj.component.html',
  styleUrls: ['./basic-data-detail-pj.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BasicDataDetailPjComponent {
  addressType: StaticDataType;
  @Input() _legalPerson: LegalPerson;
  @Output() editChange = new EventEmitter<void>();

  constructor(private addressTypeService: AddressService) {
    this.addressType = {} as StaticDataType;
    this._legalPerson = {} as LegalPerson;
  }

  @Input() set legalPerson(legalPerson: LegalPerson) {
    this._legalPerson = legalPerson;
    if (this._legalPerson.addressType !== undefined) {
      this.addressTypeService.get(this._legalPerson.addressType).subscribe({
        next: (addressType: StaticDataType) => {
          this.addressType = addressType;
        }
      });
    }
  }

  edit() {
    this.editChange.emit();
  }
}
