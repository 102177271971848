<div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Dados de Constituição</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CNAE</mat-label>
      <p>{{ legalPerson.cnae }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Número de Funcionários</mat-label>
      <p>{{ legalPerson.numberEmployees }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Capital Social</mat-label>
      <p>
        {{
          legalPerson.companyCapital?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          })
        }}
      </p>
    </div>
    <h4 class="mt-6 matrial-subTitle"><b>Dados Bancários</b></h4>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Banco</mat-label>
      <p>{{ legalPerson.bank }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Agência</mat-label>
      <p>{{ legalPerson.bankAgency }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Conta</mat-label>
      <p>{{ legalPerson.bankAccount }}</p>
    </div>
  </div>
  <div>
    <h2 class="mt-10 text-blue">
      Procuradores / Administradores / Representantes Legais
    </h2>
    <app-representative-data-detail
      *ngFor="let representative of legalPerson.representatives; let i = index"
      [representative]="representative"
      [representativeNumber]="i"
    >
    </app-representative-data-detail>
  </div>
  <div>
    <h2 class="mt-10 text-blue">Sócios</h2>
    <app-partner-data-detail
      *ngFor="let partner of legalPerson.partners; let i = index"
      [partner]="partner"
      [partnerNumber]="i"
    >
    </app-partner-data-detail>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="edit()">
      Solcitar Edição de Cadastro
    </button>
  </div>
</div>
