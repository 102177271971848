import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LegalPerson } from '../../../../types/profile/legal-person.type';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { FileType } from '../../../../types/profile/file.type';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultMessages } from '../../../../data/default-messages';

@Component({
  selector: 'app-docs-detail-pj',
  templateUrl: './docs-detail-pj.component.html',
  styleUrls: ['./docs-detail-pj.component.scss']
})
export class DocsDetailPjComponent {
  _files: { [key: string]: FileType };
  _legalPerson: LegalPerson;
  @Output() previousChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<void>();

  constructor(
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._legalPerson = {} as LegalPerson;
    this._files = {};
  }

  @Input() set legalPerson(legalPerson: LegalPerson) {
    this._legalPerson = legalPerson;
    if (this._legalPerson.files) {
      for (const file of this._legalPerson.files) {
        this._files[file.type] = file;
      }
    }
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }

  previous() {
    this.previousChange.emit();
  }

  edit() {
    this.editChange.emit();
  }
}
