export const environment = {
  production: true,
  apiUrl: 'https://202dcebf-c160-46da-928c-054da07923d0.mock.pstmn.io',
  apiCepUrl:
    'https://apps.correios.com.br/SigepMasterJPA/AtendeClienteService/AtendeCliente',
  azureAD: {
    redirectUrl: 'https://dev.cambio.semear.nscreen.com.br',
    clientId: '65bfb00d-0a87-49d7-91c7-d3f2563b21a9',
    tenantName: 'bancosemearb2c',
    userFlow: {
      signUpSignIn: 'B2C_1_DEV_NSCREEN_CAMBIO_CRIAR_ENTRAR_FLOW',
      editProfile: 'B2C_1_DEV_NSCREEN_CAMBIO_EDITAR_FLOW',
      resetPassword: 'B2C_1_DEV_NSCREEN_CAMBIO_RESETAR_SENHA_FLOW'
    }
  }
};
