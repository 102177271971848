<h2 class="mt-10 text-blue">Declaração de Política de Privacidade*</h2>
<form>
  <h2 class="mt-10 text-blue">Declarações US Person</h2>
  <div class="mt-6">
    <mat-checkbox
      color="primary"
      class="text-base text-gray"
      [disabled]="true"
      [checked]="declarationProfile.privacyPoliceAccepted"
      value="{{ declarationProfile.privacyPoliceAccepted }}"
    >
      Declaração de
      <a href="http://" class="text-cyan font-medium"
        >Políticas de Privacidade do Banco Semear S.A.</a
      >
    </mat-checkbox>
  </div>
  <div class="mt-6">
    <ol class="list-decimal text-sm text-gray">
      <li class="mt-1">1. Nascido nos EUA ou em seus territórios;</li>
      <li class="mt-1">
        2. Possuir residência permanente nos EUA legalmente (Green Card);
      </li>
      <li class="mt-1">
        3. Deter cidadania americana ou ser residente fiscal nos EUA (residente
        estrangeiro);
      </li>
      <li class="mt-1">
        4. Ser filho de pelo menos um cidadão americano ou ter pais
        naturalizados;
      </li>
      <li class="mt-1">
        5. Ter presença substancial nos EUA (183 dias durante o triênio);
      </li>
      <li class="mt-1">
        6. Empresa constituída sob as leis dos EUA (Domestic Partnership ou
        sociedade doméstica).
      </li>
    </ol>
    <mat-checkbox
      color="cyan"
      class="text-base text-gray mt-6"
      [disabled]="true"
      [checked]="declarationProfile.isUsPerson"
      value="{{ declarationProfile.isUsPerson }}"
      color="primary"
    >
      Declaração de <span class="font-medium">US Person</span>
    </mat-checkbox>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de Pessoa Exposta Politicamente (PEP)
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Conforme estabelece a Circular no 3.978 do Banco Central do Brasil, de 23
      de janeiro de 2020, as instituições financeiras devem obter de seus
      clientes permanentes informações que permitam caracterizá-los ou não como
      Pessoas Expostas Politicamente (PEP).
    </p>
    <p>
      Consideram-se PEP os agentes públicos que desempenham ou tenham
      desempenhado, nos últimos 5 (cinco) anos, no Brasil ou em países,
      territórios e dependências strangeiros, cargos, empregos ou funções
      públicas relevantes, assim como seus representantes, familiares e outras
      pessoas de seu relacionamento próximo.
    </p>
    <mat-checkbox
      class="text-base text-gray mt-6"
      [disabled]="true"
      [checked]="declarationProfile.isUsPerson"
      value="{{ declarationProfile.isUsPerson }}"
      color="primary"
    >
      Declaração de
      <span class="font-medium">Pessoa Exposta Politicamente (PEP)</span>
    </mat-checkbox>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de relacionamento/ligação com Agente Público
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Possui ou possuía relacionamento/ligação com Agente Público nos últimos 5
      (cinco) anos.
    </p>
    <mat-checkbox
      class="text-base text-gray mt-2 mb-6"
      [disabled]="true"
      [checked]="declarationProfile.isPublicAgentRelated"
      value="{{ declarationProfile.isPublicAgentRelated }}"
      color="primary"
    >
      Declaração de
      <span class="font-medium">relacionamento/ligação com Agente Público</span>
    </mat-checkbox>
    <div class="grid grid-cols-2 gap-2 mb-8 data-item">
      <mat-label>Nome completo do relacionado</mat-label>
      <p>{{ declarationProfile.publicAgentName }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Cargo ou Função</mat-label>
      <p>{{ declarationProfile.publicAgentPosition }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Tipo de Relacionamento/Ligação</mat-label>
      <p>{{ declarationProfile.publicAgentRelationshipType }}</p>
    </div>
  </div>
  <h2 class="mt-10 text-blue">
    Declaração de atividade(s) que relacionada(s) ao Risco Socioambiental
  </h2>
  <div class="mt-6 text-sm text-gray">
    <p>
      Seguindo as diretrizes da Resolução no 4.327 do Conselho Monetário
      Nacional, de 25 de abril de 2014, o CLIENTE, declara, à Instituição
      Financeira, para todos os fins legais, que exerce alguma(s) atividade(s)
      que esteja(m) relacionada(s) ao Risco Socioambiental constante no Termo de
      Compromisso e Responsabilidade Socioambiental do Banco Semear S.A.
    </p>
    <mat-checkbox
      color="primary"
      class="text-base text-gray mt-2 mb-6"
      [disabled]="true"
      [checked]="declarationProfile.isSocialEnvironmentalRisk"
      value="{{ declarationProfile.isSocialEnvironmentalRisk }}"
    >
      Declaração de
      <span class="font-medium"
        >atividade(s) que relacionada(s) ao Risco Socioambiental</span
      >
    </mat-checkbox>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Tipo de Relacionamento/Ligação</mat-label>
      <p>{{ declarationProfile.environmentalRiskActivities }}</p>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mt-10 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="edit()">
      Solcitar Edição de Cadastro
    </button>
  </div>
</form>
