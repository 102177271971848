import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { b2cPolicies } from '../auth.config';
import jwt_decode from 'jwt-decode';
import { UserTypeEnum } from '../../shared/enums/user-session.enum';
import { UserSessionType } from '../../shared/types/user-session.type';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn: BehaviorSubject<boolean>;
  private tokenClain: any;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.updateLoggedInStatus();
    this.msalService.handleRedirectObservable().subscribe({
      next: (response: AuthenticationResult) => {
        if (response && response.account) {
          this.msalService.instance.setActiveAccount(response.account);
          this.setLoggedIn();
        }
      },
      error: (error) => {
        try {
          switch (error.message.match(/[0-9A-Fa-f]{11}/g)[0]) {
            case 'AADB2C90118': // Usuário solicitou a recuperação de senha
              this.resetPassword();
              break;
            case 'AADB2C90091': // Usuário cancelou uma ação de redefinição de senha ou de cadastro
              this.msalService.loginRedirect();
              break;
          }
        } catch (e) {}
      }
    });
  }

  watchLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  isLoggedIn(): boolean {
    return this.loggedIn.value;
  }

  updateLoggedInStatus() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoggedIn();
        this.checkAndSetActiveAccount();
      });
  }

  private acquireToken(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const account = this.msalService.instance.getActiveAccount() || undefined;
      const accessTokenRequest: SilentRequest = {
        scopes: [],
        account: account
      };
      this.msalService.acquireTokenSilent(accessTokenRequest).subscribe({
        next: (res: AuthenticationResult) => {
          this.tokenClain = jwt_decode(res.idToken);
          resolve(true);
        },
        error: (err) => {
          if (err instanceof InteractionRequiredAuthError) {
            this.msalService.instance.acquireTokenRedirect(accessTokenRequest);
          }
          reject(false);
        }
      });
    });
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.loginWithPopup();
    } else {
      this.loginWithRedirect();
    }
  }

  async getCurrentUserType(): Promise<UserTypeEnum> {
    let userSession: UserSessionType;
    try {
      userSession = await this.getActiveAccount();
    } catch (_e) {
      return UserTypeEnum.NONE;
    }

    if (userSession && 'extension_Tipodaconta' in userSession) {
      const tipoConta = Number(userSession.extension_Tipodaconta);
      if (tipoConta in UserTypeEnum) {
        return 2;
      }
    }
    return UserTypeEnum.NONE;
  }

  getActiveAccount(): Promise<UserSessionType> {
    return new Promise((resolve, reject) => {
      if (!this.tokenClain) {
        this.acquireToken()
          .then(() => {
            resolve(this.tokenClain);
          })
          .catch((e) => reject(e));
      } else {
        resolve(this.tokenClain);
      }
    });
  }

  logout() {
    this.tokenClain = undefined;
    this.loggedIn.next(false);
    this.msalService.logout();
  }

  destroy() {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  private setLoggedIn() {
    this.getCurrentUserType().then((currentUserType: UserTypeEnum) => {
      if (currentUserType !== UserTypeEnum.NONE) {
        const path = this.document.location.pathname;
        this.loggedIn.next(true);
        if (path === '' || path === '/') {
          this.router.navigate(['/cotacao/listar']);
        }
      } else {
        this.loggedIn.next(false);
      }
    });
  }

  resetPassword() {
    this.msalService.loginRedirect(b2cPolicies.authorities['resetPassword']);
  }

  changePassword() {
    this.msalService.loginRedirect(b2cPolicies.authorities['resetPassword']);
  }

  editProfile() {
    this.msalService.loginRedirect(b2cPolicies.authorities['editProfile']);
  }

  private loginWithPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    }
  }

  private loginWithRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }
}
