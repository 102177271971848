import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorType } from '../../../../types/form-error.type';
import { defaultFormErros } from '../../../../enums/default-form-errors.enum';
import { ContactPhysicalPersonType } from '../../../../types/profile/physical-person.type';
import { CustomValidator } from '../../../../custom.validator';
import { StaticDataType } from '../../../../types/static-data.type';
import { AddressService } from '../../../../services/static-data/address.service';
import { FormService } from '../../../../services/form.service';

@Component({
  selector: 'app-contact-form-pf',
  templateUrl: './contact-form-pf.component.html',
  styleUrls: ['./contact-form-pf.component.scss']
})
export class ContactFormPfComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  addressTypes: Array<StaticDataType>;
  _estimatedValue!: number;
  @Input() contactData: ContactPhysicalPersonType;
  @Output() contactDataChange = new EventEmitter<ContactPhysicalPersonType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() nextChange = new EventEmitter<void>();
  @Output() previousChange = new EventEmitter<void>();

  constructor(
    private addressTypeService: AddressService,
    private formService: FormService
  ) {
    this.contactData = {} as ContactPhysicalPersonType;
    this.addressTypes = [];
    this.form = new FormGroup({
      addressType: new FormControl(this.contactData.addressType, [
        Validators.required
      ]),
      address: new FormControl(this.contactData.address, [Validators.required]),
      addressComplement: new FormControl(
        this.contactData.addressComplement,
        []
      ),
      zipCode: new FormControl(this.contactData.zipCode, [Validators.required]),
      addressNumber: new FormControl(this.contactData.addressNumber, [
        Validators.required
      ]),
      neighborhood: new FormControl(this.contactData.neighborhood, [
        Validators.required
      ]),
      country: new FormControl(this.contactData.country, []),
      city: new FormControl(this.contactData.city, [Validators.required]),
      federatedState: new FormControl(this.contactData.federatedState, [
        Validators.required
      ]),
      email: new FormControl(this.contactData.email, [
        Validators.required,
        CustomValidator.emailValidator()
      ]),
      phone: new FormControl(this.contactData.phone, [Validators.required]),
      birthCountry: new FormControl(this.contactData.birthCountry, []),
      birthCity: new FormControl(this.contactData.birthCity, []),
      birthState: new FormControl(this.contactData.birthState, [])
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  @Input()
  set estimatedValue(estimatedValue: number) {
    this._estimatedValue = Number(estimatedValue);

    if (Number(this._estimatedValue) === 0) {
      this.birthCountry.setValidators(null);
      this.birthCity.setValidators(null);
      this.birthState.setValidators(null);
    } else if (Number(this._estimatedValue) === 1) {
      this.birthCountry.setValidators([Validators.required]);
      this.birthCity.setValidators([Validators.required]);
      this.birthState.setValidators([Validators.required]);
    }
    this.birthCountry.updateValueAndValidity();
    this.birthCity.updateValueAndValidity();
    this.birthState.updateValueAndValidity();
  }

  ngOnInit() {
    this.addressType?.valueChanges.subscribe((addressType: number) => {
      if (Number(addressType) === 0) {
        this.country.setValidators(null);
      } else if (Number(addressType) === 1) {
        this.country.setValidators([Validators.required]);
      }
      this.country.updateValueAndValidity();
    });

    this.addressTypeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.addressTypes = data;
      }
    });

    this.addressTypeService.getByCep('31260390').subscribe({
      next: (data: any) => {
        console.log('data', data);
      },
      error: (err: any) => {
        console.log('err', err);
      }
    });
  }

  get addressType() {
    return this.form.get('addressType');
  }

  get address() {
    return this.form.get('address')!;
  }

  get addressNumber() {
    return this.form.get('addressNumber')!;
  }

  get zipCode() {
    return this.form.get('zipCode')!;
  }

  get addressComplement() {
    return this.form.get('addressComplement')!;
  }

  get neighborhood() {
    return this.form.get('neighborhood')!;
  }

  get country() {
    return this.form.get('country')!;
  }

  get city() {
    return this.form.get('city')!;
  }

  get federatedState() {
    return this.form.get('federatedState')!;
  }

  get email() {
    return this.form.get('email')!;
  }

  get phone() {
    return this.form.get('phone')!;
  }

  get birthCountry() {
    return this.form.get('birthCountry')!;
  }

  get birthCity() {
    return this.form.get('birthCity')!;
  }

  get birthState() {
    return this.form.get('birthState')!;
  }

  updateData() {
    this.contactData = this.form.value;
    this.contactDataChange.emit(this.contactData);
    this.validChange.emit(this.form.valid);
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }

  previous() {
    this.previousChange.emit();
  }
}
