<mat-toolbar class="bg-navy relative">
  <div class="container mx-auto">
    <div class="columns-2 flex justify-between">
      <img src="../../../assets/logo.svg" />
      <button mat-button class="text-neutral" (click)="logout()">Sair</button>
    </div>
  </div>

  <button
    mat-icon-button
    class="example-icon flex lg:hidden"
    aria-label="Example icon-button with menu icon"
    mat-button
    (click)="drawer.toggle()"
  >
    <mat-icon class="text-neutral">menu</mat-icon>
  </button>
</mat-toolbar>

<app-navbar-component
  [availableRoutes]="availableRoutes"
></app-navbar-component>

<mat-drawer-container autosize class="sidenav-container" hasBackdrop="true">
  <mat-drawer #drawer class="sidenav" mode="side" position="end">
    <mat-nav-list class="flex flex-col justify-around pt-0">
      <a
        mat-list-item
        [routerLink]="[routeItem.route]"
        routerLinkActive="active-link"
        *ngFor="let routeItem of availableRoutes"
      >
        <span class="flex items-center w-full">
          <mat-icon class="mr-2.5">
            {{ routeItem.icon }}
          </mat-icon>
          <p class="mb-0">{{ routeItem.title }}</p>
        </span>
      </a>
      <button mat-button class="mt-4 text-center" (click)="logout()">
        Sair
      </button>
    </mat-nav-list>
  </mat-drawer>
</mat-drawer-container>
