import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { defaultFormErros } from 'src/app/shared/enums/default-form-errors.enum';
import { FormErrorType } from 'src/app/shared/types/form-error.type';
import { StaticDataType } from '../../../../types/static-data.type';
import { PartnerTypeService } from '../../../../services/static-data/partner-type.service';
import { v4 as uuidv4 } from 'uuid';
import { FileType } from '../../../../types/profile/file.type';
import { FormService } from '../../../../services/form.service';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { Validators } from '@angular/forms';
import { CustomValidator } from '../../../../custom.validator';
import { profileFileAccepted } from '../../../../data/profile-files-accepted';
import { defaultMessages } from '../../../../data/default-messages';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartnerType } from '../../../../types/profile/legal-person.type';

@Component({
  selector: 'app-partner-data-form',
  templateUrl: './partner-data-form.component.html',
  styleUrls: ['./partner-data-form.component.scss']
})
export class PartnerDataFormComponent implements OnInit {
  private representativeId = uuidv4();
  profileFileAccepted: string = profileFileAccepted;
  partnerTypes: Array<StaticDataType>;
  errorMessages: FormErrorType = defaultFormErros;
  _files: { [key: string]: FileType };
  @Input() formPartner!: any;
  @Input() partnerNumber!: number;
  @Input() _partner!: PartnerType;
  @Output() filesChange = new EventEmitter<Array<FileType>>();
  @Output() increasePartner = new EventEmitter<void>();
  @Output() removePartner = new EventEmitter<void>();

  constructor(
    private partnerTypeService: PartnerTypeService,
    private formService: FormService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._partner = {} as PartnerType;
    this._files = {};
    this.partnerTypes = [];
  }

  ngOnInit(): void {
    this.partnerTypeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.partnerTypes = data;
      }
    });

    this.shareholding?.valueChanges.subscribe((shareholding: number) => {
      if (shareholding > 100) {
        this.shareholding.setValue(100);
      } else if (shareholding < 0) {
        this.shareholding.setValue(0);
      }
    });

    this.type?.valueChanges.subscribe((type: number) => {
      if (Number(type) === 0) {
        this.documentNumber.setValidators([
          Validators.required,
          CustomValidator.cpfValidator
        ]);
      } else {
        this.documentNumber.setValidators([
          Validators.required,
          CustomValidator.cnpjValidator
        ]);
      }
      this.documentNumber.updateValueAndValidity();
    });
  }

  @Input() set partner(partner: PartnerType | undefined) {
    this._partner = partner || ({} as PartnerType);
    if (this._partner.files) {
      for (const file of this._partner.files) {
        this._files[file.type] = file;
      }
    }
  }

  @Input()
  set files(files: Array<FileType> | undefined) {
    if (files) {
      for (const file of files) {
        this._files[file.type] = file;
      }
    }
  }

  get type() {
    return this.formPartner.get('type');
  }

  get documentNumber() {
    return this.formPartner.get('documentNumber');
  }

  get name() {
    return this.formPartner.get('name');
  }

  get email() {
    return this.formPartner.get('email');
  }

  get phone() {
    return this.formPartner.get('phone');
  }

  get shareholding() {
    return this.formPartner.get('shareholding');
  }

  get asset() {
    return this.formPartner.get('asset');
  }

  get monthlyIncome() {
    return this.formPartner.get('monthlyIncome');
  }

  get balance() {
    return this.formPartner.get('balance');
  }

  get invoicing() {
    return this.formPartner.get('invoicing');
  }

  get constitutiveActs() {
    return this.formPartner.get('constitutiveActs');
  }

  addPartner() {
    this.increasePartner.emit();
  }

  excludePartner() {
    this.removePartner.emit();
  }

  updateFiles(files: { [key: string]: FileType }) {
    this._files = files;
    this.filesChange.emit(Object.values(this._files));
  }

  changeFile(event: any, type: string) {
    this.profileFileService
      .changeFileData(this._files, event, type, this.representativeId)
      .then((files: { [key: string]: FileType }) => {
        this.updateFiles(files);
        this.snackBar.open(defaultMessages.upload_success, 'Fechar', {
          panelClass: ['snack-success'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      })
      .catch(() => {
        this.snackBar.open(defaultMessages.upload_failed, 'Fechar', {
          panelClass: ['snack-error'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      });
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }
}
