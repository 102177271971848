<div>
  <h3 class="mt-10 text-blue">Sócio {{ (partnerNumber || 0) + 1 }}</h3>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>{{ _partner.type === 0 ? 'CPF' : 'CNPJ' }}</mat-label>
    <p>
      {{
        _partner.documentNumber
          | mask
            : (_partner.type === 0 ? '000.000.000-00' : '00.000.000/0000-00')
      }}
    </p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Nome do Sócio</mat-label>
    <p>{{ _partner.name }}</p>
  </div>

  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Email de Contato</mat-label>
    <p>{{ _partner.email }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Telefone de Contato</mat-label>
    <p>{{ _partner.phone | mask: '(00) 0000-0000||(00) 0 0000-0000' }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Participação</mat-label>
    <p>{{ _partner.shareholding }}%</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Patrimônio</mat-label>
    <p>
      {{
        _partner.asset.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      }}
    </p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Renda Média Mensal</mat-label>
    <p>
      {{
        _partner.monthlyIncome.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        })
      }}
    </p>
  </div>
  <h4 class="mt-6 matrial-subTitle"><b>Arquivos e Documentos</b></h4>
  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <div class="w-full" *ngIf="_files['balance']">
      <mat-label class="text-sm text-gray">Balanço</mat-label>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('balance')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
    <div class="w-full" *ngIf="_files['invoicing']">
      <mat-label class="text-sm text-gray">Faturamento</mat-label>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('invoicing')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <div class="w-full" *ngIf="_files['invoicing']">
      <mat-label class="text-sm text-gray">Atos Constitutivos</mat-label>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('invoicing')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
  </div>
</div>
