<h2 class="mt-10 text-blue">Dados da Empresa</h2>
<form [formGroup]="form">
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="cnpj?.invalid && (cnpj?.dirty || cnpj?.touched)"
        class="invalid-feedback"
      >
        <div
          *ngIf="cnpj?.errors?.['required'] || cnpj?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="cnpj?.errors?.['cnpjInvalid']">
          {{ errorMessages.INVALID_CNPJ }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>CNPJ</mat-label>
        <input
          formControlName="cnpj"
          [(ngModel)]="basicData.cnpj"
          required
          mask="00.000.000/0000-00"
          placeholder="xx.xxx.xxx/xxxx-xx"
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          tradingName?.invalid && (tradingName?.dirty || tradingName?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="tradingName?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Nome Fantasia</mat-label>
        <input
          formControlName="tradingName"
          [(ngModel)]="basicData.tradingName"
          required
          matInput
        />
      </mat-form-field>
    </div>
  </div>
  <div>
    <div
      *ngIf="
        companyName?.invalid && (companyName?.dirty || companyName?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="companyName?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Razão Social</mat-label>
      <input
        formControlName="companyName"
        [(ngModel)]="basicData.companyName"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <h2 class="mt-4 text-blue">Informações de Contato</h2>
  <div>
    <div
      *ngIf="
        accountableName?.invalid &&
        (accountableName?.dirty || accountableName?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="accountableName?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Nome do Responsável</mat-label>
      <input
        formControlName="accountableName"
        [(ngModel)]="basicData.accountableName"
        required
        matInput
      />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="
          accountableEmail?.invalid &&
          (accountableEmail?.dirty || accountableEmail?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="accountableEmail?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
        <div *ngIf="accountableEmail?.errors?.['pattern']">
          {{ errorMessages.INVALID_EMAIL }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>E-mail de Contato</mat-label>
        <input
          formControlName="accountableEmail"
          [(ngModel)]="basicData.accountableEmail"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          accountablePhone?.invalid &&
          (accountablePhone?.dirty || accountablePhone?.touched)
        "
        class="invalid-feedback"
      >
        <div
          *ngIf="accountablePhone?.errors?.['required'] || accountablePhone?.errors?.['mask']?.['requiredMask']"
        >
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Telefone de Contato</mat-label>
        <input
          formControlName="accountablePhone"
          [(ngModel)]="basicData.accountablePhone"
          required
          matInput
          placeholder="(xx) xxxx-xxxx"
          mask="(00) 0000-0000||(00) 0 0000-0000"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="grid">
    <div
      *ngIf="
        allowedEmailUse?.invalid &&
        (allowedEmailUse?.dirty || allowedEmailUse?.touched)
      "
      class="invalid-feedback w-full"
    >
      <div *ngIf="allowedEmailUse?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <div class="flex">
      <mat-checkbox
        formControlName="allowedEmailUse"
        [(ngModel)]="basicData.allowedEmailUse"
        required
        class="mr-2"
        color="primary"
      >
      </mat-checkbox>
      <div color="cyan" class="text-base text-gray">
        <p>
          O SEMEAR utilizará o correio eletrônico para realizar todas as suas
          comunicações com o CLIENTE, por meio do e-mail informado neste
          documento.
        </p>
        <p>
          O CLIENTE, desde já, declara-se ciente e de acordo com a utilização do
          seu e-mail acima informado como meio hábil para receber as
          comunicações e notificações.
        </p>
      </div>
    </div>
  </div>
  <h2 class="mt-4 text-blue">Endereço</h2>
  <div>
    <div
      *ngIf="
        addressType?.invalid && (addressType?.dirty || addressType?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="addressType?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Tipo de endereço</mat-label>
      <mat-select
        formControlName="addressType"
        [(ngModel)]="basicData.addressType"
        required
      >
        <mat-option
          *ngFor="let addressType of addressTypes"
          [value]="addressType.id"
          >{{ addressType.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="[0, 1].includes(addressType?.value)">
    <div *ngIf="addressType?.value === 1">
      <div
        *ngIf="country?.invalid && (country?.dirty || country?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="country?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>País</mat-label>
        <input
          formControlName="country"
          [(ngModel)]="basicData.country"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="zipCode?.invalid && (zipCode?.dirty || zipCode?.touched)"
          class="invalid-feedback"
        >
          <div
            *ngIf="zipCode?.errors?.['required'] || zipCode?.errors?.['mask']?.['requiredMask']"
          >
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label *ngIf="addressType?.value === 0">CEP</mat-label>
          <mat-label *ngIf="addressType?.value === 1">Código postal</mat-label>
          <input
            mask="00.000-000"
            *ngIf="addressType?.value === 0"
            formControlName="zipCode"
            [(ngModel)]="basicData.zipCode"
            placeholder="xx.xxx-xxx"
            required
            matInput
          />
          <input
            *ngIf="addressType?.value === 1"
            formControlName="zipCode"
            [(ngModel)]="basicData.zipCode"
            required
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="address?.invalid && (address?.dirty || address?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="address?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Endereço</mat-label>
          <input
            formControlName="address"
            [(ngModel)]="basicData.address"
            required
            matInput
          />
        </mat-form-field>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="
            addressNumber?.invalid &&
            (addressNumber?.dirty || addressNumber?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="addressNumber?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Número</mat-label>
          <input
            type="number"
            formControlName="addressNumber"
            [(ngModel)]="basicData.addressNumber"
            matInput
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            addressComplement?.invalid &&
            (addressComplement?.dirty || addressComplement?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="addressComplement?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Complemento</mat-label>
          <input
            formControlName="addressComplement"
            [(ngModel)]="basicData.addressComplement"
            matInput
          />
        </mat-form-field>
      </div>
    </div>
    <div>
      <div
        *ngIf="
          neighborhood?.invalid &&
          (neighborhood?.dirty || neighborhood?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="neighborhood?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Bairro</mat-label>
        <input
          formControlName="neighborhood"
          [(ngModel)]="basicData.neighborhood"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="city?.invalid && (city?.dirty || city?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="city?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>Cidade</mat-label>
          <input
            *ngIf="addressType?.value === 1"
            formControlName="city"
            [(ngModel)]="basicData.city"
            required
            matInput
          />
          <mat-select
            *ngIf="addressType?.value === 0"
            formControlName="city"
            [(ngModel)]="basicData.city"
            required
          >
            <mat-option value="one">Cidade 1</mat-option>
            <mat-option value="two">Cidade 2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="
            federatedState?.invalid &&
            (federatedState?.dirty || federatedState?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="federatedState?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>UF</mat-label>
          <input
            *ngIf="addressType?.value === 1"
            formControlName="federatedState"
            [(ngModel)]="basicData.federatedState"
            required
            matInput
          />
          <mat-select
            *ngIf="addressType?.value === 0"
            formControlName="federatedState"
            [(ngModel)]="basicData.federatedState"
            required
          >
            <mat-option value="one">Estado 1</mat-option>
            <mat-option value="two">Estado 2</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div></div>
    <button mat-button (click)="next()" class="w-full bg-cyan text-neutral-100">
      Prosseguir
    </button>
  </div>
</form>
