import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

type SensediaAccessToken = {
  access_token: string;
  refresh_token: string;
  token_type: string;
  expires_in: number;
};

type GrantCode = {
  redirect_uri: string;
};

type GrantCodeRequest = {
  client_id: string;
  redirect_uri: string;
};

type AccessCodeRequest = {
  grant_type: 'authorization_code';
  code: string;
};

type RefreshTokenRequest = {
  grant_type: 'refresh_token';
  refresh_token: string;
};

enum RequestStatus {
  REQUESTING = 0,
  REQUESTED = 1,
  NONE = -1
}

@Injectable({
  providedIn: 'root'
})
export class SensediaService {
  private sensediaToken: SensediaAccessToken;
  private sensediaCookieName: string = 'sensedia-access-token';
  private expireTokenSalt: number = -30;
  private requestStatus: BehaviorSubject<RequestStatus>;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.sensediaToken = {} as SensediaAccessToken;
    this.requestStatus = new BehaviorSubject<RequestStatus>(RequestStatus.NONE);
  }

  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const accessToken = this.cookieService.get(this.sensediaCookieName);
      if (accessToken) {
        resolve(JSON.parse(accessToken).access_token);
      } else {
        const requestStatusSubscription = this.requestStatus.subscribe({
          next: (requestStatus: RequestStatus) => {
            if (requestStatus === RequestStatus.NONE) {
              this.requestGrantToken().then((grantCode: string) => {
                this.requestAccessToken(grantCode).then(
                  (accessToken: SensediaAccessToken) => {
                    resolve(accessToken.access_token);
                    this.sensediaToken = accessToken;
                    this.updateAccessTokenOnCookie();
                    requestStatusSubscription.unsubscribe();
                  }
                );
              });
            } else if (requestStatus === RequestStatus.REQUESTED) {
              resolve(this.sensediaToken.access_token);
              requestStatusSubscription.unsubscribe();
            } else {
              reject();
              requestStatusSubscription.unsubscribe();
            }
          },
          error: () => {
            this.requestStatus.next(RequestStatus.NONE);
          }
        });
      }
    });
  }

  private updateAccessTokenOnCookie() {
    this.cookieService.set(
      this.sensediaCookieName,
      JSON.stringify(this.sensediaToken),
      this.sensediaToken.expires_in - this.expireTokenSalt
    );
  }

  private requestAccessToken(code: string): Promise<SensediaAccessToken> {
    return new Promise((resolve, reject) => {
      const body: AccessCodeRequest = {
        grant_type: 'authorization_code',
        code: code
      };

      this.http
        .post<SensediaAccessToken>(
          'https://devapi2.bancosemear.com.br/oauth/access-token',
          body,
          {
            headers: this.getAccessTokenHeaders()
          }
        )
        .subscribe({
          next: (accessToken: SensediaAccessToken) => {
            resolve(accessToken);
          },
          error: (err) => reject(err)
        });
    });
  }

  private getBasicAuthorization() {
    return 'Basic '; //+ environment.sensedia.basicAuthorization;
  }

  private getAccessTokenHeaders() {
    return {
      Authorization: this.getBasicAuthorization()
    };
  }

  private refreshAccessToken(
    accessToken: SensediaAccessToken
  ): Promise<SensediaAccessToken> {
    return new Promise((resolve, reject) => {
      const body: RefreshTokenRequest = {
        grant_type: 'refresh_token',
        refresh_token: accessToken.refresh_token
      };

      this.http
        .post<SensediaAccessToken>(
          'https://devapi2.bancosemear.com.br/oauth/access-token',
          body,
          {
            headers: this.getAccessTokenHeaders()
          }
        )
        .subscribe({
          next: (accessToken: SensediaAccessToken) => {
            resolve(accessToken);
          },
          error: (err) => reject(err)
        });
    });
  }

  private requestGrantToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const body: GrantCodeRequest = {
        client_id: '', //environment.sensedia.client_id,
        redirect_uri: this.document.location.origin
      };

      this.http
        .post<GrantCode>(
          'https://devapi2.bancosemear.com.br/oauth/grant-code',
          body
        )
        .subscribe({
          next: (grantCode: GrantCode) => {
            const redirectUriBaseResponse =
              this.document.location.origin + '/?code=';
            resolve(
              grantCode.redirect_uri.replace(redirectUriBaseResponse, '')
            );
          },
          error: (err) => reject(err)
        });
    });
  }
}
