import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhysicalPerson } from '../../../../types/profile/physical-person.type';
import { AddressService } from '../../../../services/static-data/address.service';
import { StaticDataType } from '../../../../types/static-data.type';

@Component({
  selector: 'app-contact-detail-pf',
  templateUrl: './contact-detail-pf.component.html',
  styleUrls: ['./contact-detail-pf.component.scss']
})
export class ContactDetailPfComponent {
  _physicalPerson: PhysicalPerson;
  addressType: StaticDataType;
  @Output() previousChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<void>();

  constructor(private addressTypeService: AddressService) {
    this._physicalPerson = {} as PhysicalPerson;
    this.addressType = {} as StaticDataType;
  }

  @Input() set physicalPerson(physicalPerson: PhysicalPerson) {
    this._physicalPerson = physicalPerson;
    if (this._physicalPerson.status !== undefined) {
      this.addressTypeService.get(this._physicalPerson.addressType).subscribe({
        next: (addressType: StaticDataType) => {
          this.addressType = addressType;
        }
      });
    }
  }

  previous() {
    this.previousChange.emit();
  }

  edit() {
    this.editChange.emit();
  }
}
