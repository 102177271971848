import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserTypeEnum } from '../../shared/enums/user-session.enum';

@Injectable({
  providedIn: 'root'
})
export class DefaultRedirectGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService
      .getCurrentUserType()
      .then((currentUserType: UserTypeEnum) => {
        if (currentUserType !== UserTypeEnum.NONE) {
          if (
            currentUserType in
            [
              UserTypeEnum.LEGAL_PERSON,
              UserTypeEnum.PHYSICAL_PERSON,
              UserTypeEnum.CORBAN
            ]
          ) {
            if (currentUserType === UserTypeEnum.CORBAN) {
              this.router.navigate(['/perfil/corban/cadastro']);
            } else if (currentUserType === UserTypeEnum.PHYSICAL_PERSON) {
              this.router.navigate(['/perfil/pf/cadastro']);
            } else {
              this.router.navigate(['/perfil/pj/cadastro']);
            }
            return false;
          } else {
            this.router.navigate(['/cotacao/listar']);
          }
        } else {
          // TODO colocar rota de cotação como default
          if (this.authService.isLoggedIn()) {
            this.authService.logout();
          } else {
            this.authService.login();
          }
        }
        return true;
      });
  }
}
