import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorType } from '../../../../types/form-error.type';
import { defaultFormErros } from '../../../../enums/default-form-errors.enum';
import { DocsLegalPersonType } from '../../../../types/profile/legal-person.type';
import { FormService } from '../../../../services/form.service';
import { profileFileAccepted } from '../../../../data/profile-files-accepted';
import { FileType } from '../../../../types/profile/file.type';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultMessages } from '../../../../data/default-messages';

@Component({
  selector: 'app-docs-form-pj',
  templateUrl: './docs-form-pj.component.html',
  styleUrls: ['./docs-form-pj.component.scss']
})
export class DocsFormPjComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  _estimatedValue!: number;
  _files: { [key: string]: FileType };
  _docData: DocsLegalPersonType;
  profileFileAccepted: string = profileFileAccepted;
  @Output() docDataChange = new EventEmitter<DocsLegalPersonType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() nextChange = new EventEmitter<void>();
  @Output() previousChange = new EventEmitter<void>();

  constructor(
    private formService: FormService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._files = {};
    this._docData = {
      documentType: 0
    } as DocsLegalPersonType;
    this.form = new FormGroup({
      documentType: new FormControl(this._docData.documentType, [
        Validators.required
      ]),
      socialContract: new FormControl(null),
      bylaws: new FormControl(null),
      ata: new FormControl(null),
      proofResidence: new FormControl(null, [Validators.required]),
      balance: new FormControl(null, [Validators.required]),
      billingsLastTwelveMonths: new FormControl(null, [Validators.required]),
      defis: new FormControl(null),
      deliveryReceipt: new FormControl(null),
      pgdas: new FormControl(null)
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit(): void {
    this.documentType?.valueChanges.subscribe((documentType: number) => {
      if (Number(documentType) === 0) {
        this.socialContract?.setValidators([Validators.required]);
        this.bylaws?.setValidators(null);
        this.ata?.setValidators(null);
      } else if (Number(documentType) === 1) {
        this.socialContract?.setValidators(null);
        this.bylaws?.setValidators([Validators.required]);
        this.ata?.setValidators(null);
      } else if (Number(documentType) === 2) {
        this.socialContract?.setValidators(null);
        this.bylaws?.setValidators(null);
        this.ata?.setValidators([Validators.required]);
      }
      this.socialContract?.setValue(null);
      this.bylaws?.setValue(null);
      this.ata?.setValue(null);
      this.socialContract?.updateValueAndValidity();
      this.bylaws?.updateValueAndValidity();
      this.ata?.updateValueAndValidity();
    });
  }

  @Input() set docData(docData: DocsLegalPersonType) {
    this._docData = docData;
    if (this._docData.files) {
      for (const file of this._docData.files) {
        this._files[file.type] = file;
      }
    }
  }

  @Input()
  set estimatedValue(estimatedValue: number) {
    this._estimatedValue = Number(estimatedValue);
  }

  get documentType() {
    return this.form.get('documentType');
  }

  get proofResidence() {
    return this.form.get('proofResidence');
  }

  get socialContract() {
    return this.form.get('socialContract');
  }

  get ata() {
    return this.form.get('ata');
  }

  get bylaws() {
    return this.form.get('bylaws');
  }

  get balance() {
    return this.form.get('balance');
  }

  get billingsLastTwelveMonths() {
    return this.form.get('billingsLastTwelveMonths');
  }

  get defis() {
    return this.form.get('defis');
  }

  get pgdas() {
    return this.form.get('pgdas');
  }

  get deliveryReceipt() {
    return this.form.get('deliveryReceipt');
  }

  updateData() {
    this._docData = this.form.value;
    this.docDataChange.emit(this._docData);
    this.validChange.emit(this.form.valid);
  }

  updateFiles(files: { [key: string]: FileType }) {
    this._files = files;
    this._docData.files = Object.values(this._files);
    this.docDataChange.emit(this._docData);
  }

  changeFile(event: any, type: string) {
    this.profileFileService
      .changeFileData(this._files, event, type)
      .then((files: { [key: string]: FileType }) => {
        this.updateFiles(files);
        this.snackBar.open(defaultMessages.upload_success, 'Fechar', {
          panelClass: ['snack-success'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      })
      .catch(() => {
        this.snackBar.open(defaultMessages.upload_failed, 'Fechar', {
          panelClass: ['snack-error'],
          horizontalPosition: 'end',
          verticalPosition: 'top',
          duration: 5 * 1000
        });
      });
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }

  previous() {
    this.previousChange.emit();
  }
}
