<div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Dados da Empresa</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CNPJ</mat-label>
      <p>{{ _legalPerson.cnpj | mask: '00.000.000/0000-00' }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Nome Fantasia</mat-label>
      <p>{{ _legalPerson.tradingName }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Razão Social</mat-label>
      <p>{{ _legalPerson.companyName }}</p>
    </div>
  </div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Informações de Contato</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Nome do Responsável</mat-label>
      <p>{{ _legalPerson.accountableName }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>E-mail de Contato</mat-label>
      <p>{{ _legalPerson.accountableEmail }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Telefone de Contato</mat-label>
      <p>
        {{
          _legalPerson.accountablePhone
            | mask
              : (_legalPerson.accountablePhone &&
                _legalPerson.accountablePhone.length > 10
                  ? '(00) 0 0000-0000'
                  : '(00) 0000-0000')
        }}
      </p>
    </div>
    <div class="flex">
      <mat-checkbox
        required
        class="mr-2"
        [disabled]="true"
        [checked]="_legalPerson.allowedEmailUse"
        color="primary"
      >
      </mat-checkbox>
      <div color="cyan" class="text-base text-gray">
        <p>
          O SEMEAR utilizará o correio eletrônico para realizar todas as suas
          comunicações com o CLIENTE, por meio do e-mail informado neste
          documento.
        </p>
        <p>
          O CLIENTE, desde já, declara-se ciente e de acordo com a utilização do
          seu e-mail acima informado como meio hábil para receber as
          comunicações e notificações.
        </p>
      </div>
    </div>
  </div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Informações de Contato</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Tipo de Endereço</mat-label>
      <p>{{ addressType.name }}</p>
    </div>
    <div
      *ngIf="_legalPerson.addressType === 1"
      class="grid grid-cols-2 gap-2 my-8 data-item"
    >
      <mat-label>País</mat-label>
      <p>{{ _legalPerson.country }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CEP</mat-label>
      <p>{{ _legalPerson.zipCode | mask: '00.000-000' }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Endereço</mat-label>
      <p>{{ _legalPerson.address }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Número</mat-label>
      <p>{{ _legalPerson.addressNumber }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Complemento</mat-label>
      <p>{{ _legalPerson.addressComplement }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Bairro</mat-label>
      <p>{{ _legalPerson.neighborhood }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Cidade</mat-label>
      <p>{{ _legalPerson.city }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>UF</mat-label>
      <p>{{ _legalPerson.federatedState }}</p>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 text-right gap-x-8">
    <div></div>
    <div>
      <button
        mat-button
        class="w-full bg-cyan text-neutral-100"
        (click)="edit()"
      >
        Solcitar Edição de Cadastro
      </button>
    </div>
  </div>
</div>
