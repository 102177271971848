import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { StaticDataType } from '../../types/static-data.type';

@Injectable({
  providedIn: 'root'
})
export abstract class StaticDataService {
  data: Array<StaticDataType>;

  constructor() {
    this.data = [];
  }

  list(): Observable<Array<StaticDataType>> {
    return new Observable<Array<StaticDataType>>(
      (observer: Subscriber<Array<StaticDataType>>) => {
        observer.next(this.data);
      }
    );
  }

  get(id: number | undefined): Observable<StaticDataType> {
    return new Observable<StaticDataType>(
      (observer: Subscriber<StaticDataType>) => {
        const item = this.data.find((item: StaticDataType) => item.id === id);
        if (item) {
          observer.next(item);
        } else {
          observer.error({
            error: 'NOT_FOUND'
          });
        }
      }
    );
  }
}
