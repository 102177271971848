import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorType } from '../../../types/form-error.type';
import { defaultFormErros } from '../../../enums/default-form-errors.enum';
import { DeclarationProfileType } from '../../../types/profile/declaration.type';
import { FormService } from '../../../services/form.service';

@Component({
  selector: 'app-profile-declaration-form',
  templateUrl: './declaration-form.component.html',
  styleUrls: ['./declaration-form.component.scss']
})
export class DeclarationFormComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  @Input() declarationData: DeclarationProfileType;
  @Output() declarationDataChange = new EventEmitter<DeclarationProfileType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() submitChange = new EventEmitter<void>();
  @Output() previousChange = new EventEmitter<void>();

  constructor(private formService: FormService) {
    this.declarationData = {} as DeclarationProfileType;
    this.form = new FormGroup({
      privacyPoliceAccepted: new FormControl(
        this.declarationData.privacyPoliceAccepted,
        [Validators.required]
      ),
      isUsPerson: new FormControl(this.declarationData.isUsPerson),
      isPoliticallyExposed: new FormControl(
        this.declarationData.isPoliticallyExposed
      ),
      isPublicAgentRelated: new FormControl(
        this.declarationData.isPublicAgentRelated
      ),
      publicAgentName: new FormControl(this.declarationData.publicAgentName),
      publicAgentPosition: new FormControl(
        this.declarationData.publicAgentPosition
      ),
      publicAgentRelationshipType: new FormControl(
        this.declarationData.publicAgentRelationshipType
      ),
      isSocialEnvironmentalRisk: new FormControl(
        this.declarationData.isSocialEnvironmentalRisk
      ),
      environmentalRiskActivities: new FormControl(
        this.declarationData.environmentalRiskActivities
      )
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit() {
    this.isSocialEnvironmentalRisk.valueChanges.subscribe((value: number) => {
      if (value) {
        this.environmentalRiskActivities.setValidators([Validators.required]);
      } else {
        this.environmentalRiskActivities.setValidators(null);
      }
      this.environmentalRiskActivities.updateValueAndValidity();
    });
    this.isPublicAgentRelated.valueChanges.subscribe((value: number) => {
      if (value) {
        this.publicAgentName.setValidators([Validators.required]);
        this.publicAgentPosition.setValidators([Validators.required]);
        this.publicAgentRelationshipType.setValidators([Validators.required]);
      } else {
        this.publicAgentName.setValidators(null);
        this.publicAgentPosition.setValidators(null);
        this.publicAgentRelationshipType.setValidators(null);
      }
      this.publicAgentName.updateValueAndValidity();
      this.publicAgentPosition.updateValueAndValidity();
      this.publicAgentRelationshipType.updateValueAndValidity();
    });
  }

  get privacyPoliceAccepted() {
    return this.form.get('privacyPoliceAccepted')!;
  }

  get isUsPerson() {
    return this.form.get('isUsPerson')!;
  }

  get isPoliticallyExposed() {
    return this.form.get('isPoliticallyExposed')!;
  }

  get isPublicAgentRelated() {
    return this.form.get('isPublicAgentRelated')!;
  }

  get publicAgentName() {
    return this.form.get('publicAgentName')!;
  }

  get publicAgentPosition() {
    return this.form.get('publicAgentPosition')!;
  }

  get publicAgentRelationshipType() {
    return this.form.get('publicAgentRelationshipType')!;
  }

  get isSocialEnvironmentalRisk() {
    return this.form.get('isSocialEnvironmentalRisk')!;
  }

  get environmentalRiskActivities() {
    return this.form.get('environmentalRiskActivities')!;
  }

  updateData() {
    this.declarationData = this.form.value;
    this.declarationDataChange.emit(this.declarationData);
    this.validChange.emit(this.form.valid);
  }

  submit() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.submitChange.emit();
  }

  previous() {
    this.previousChange.emit();
  }
}
