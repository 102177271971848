import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class MonthlyIncomeService extends StaticDataService {
  constructor() {
    super();
    this.data = [
      {
        id: 0,
        name: '0,00 a 1.000,00'
      },
      {
        id: 1,
        name: '1.000,01 a 5.000,00'
      },
      {
        id: 2,
        name: '5.000,01 - 10.000,00'
      },
      {
        id: 2,
        name: 'Acima de 10.000,00'
      }
    ];
  }
}
