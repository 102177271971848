<div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Valor Estimado a Operar</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Valor Estimado a Operar</mat-label>
      <p>{{ estimatedOperationValue.name }}</p>
    </div>
  </div>
  <div>
    <h2 class="mt-10 mb-6 text-blue">Informações Básicas</h2>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Nome Completo</mat-label>
      <p>{{ _physicalPerson.name }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CPF</mat-label>
      <p>{{ _physicalPerson.cpf | mask: '000.000.000-00' }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Data de Nascimento </mat-label>
      <p>{{ birthDate }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Gênero</mat-label>
      <p>{{ gender.name }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Estado Civil</mat-label>
      <p>{{ maritalStatus.name }}</p>
    </div>
    <div *ngIf="_physicalPerson.estimatedOperationValue === 1">
      <div class="grid grid-cols-2 gap-2 my-8 data-item">
        <mat-label>Nome da mãe</mat-label>
        <p>{{ _physicalPerson.motherName }}</p>
      </div>
      <div class="grid grid-cols-2 gap-2 my-8 data-item">
        <mat-label>Nome do pai</mat-label>
        <p>{{ _physicalPerson.fatherName }}</p>
      </div>
      <div class="grid grid-cols-2 gap-2 my-8 data-item">
        <mat-label>Profissão</mat-label>
        <p>{{ _physicalPerson.profession }}</p>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Renda Média Mensal</mat-label>
      <p>{{ monthlyIncome.name }}</p>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 text-right gap-x-8">
    <div></div>
    <div>
      <button
        mat-button
        class="w-full bg-cyan text-neutral-100"
        (click)="edit()"
      >
        Solcitar Edição de Cadastro
      </button>
    </div>
  </div>
</div>
