<h2 class="mt-10 mb-6 text-blue">Envio de Documentos</h2>
<form [formGroup]="form">
  <div class="grid grid-cols-3 gap-8">
    <div>
      <div
        *ngIf="
          documentType?.invalid &&
          (documentType?.dirty || documentType?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="documentType?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <div class="my-8">
        <mat-radio-group
          formControlName="documentType"
          [(ngModel)]="_docData.documentType"
        >
          <mat-radio-button [value]="0" [checked]="documentType?.value === 0"
            >Contrato Social</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="documentType?.value === 0">
        <div
          *ngIf="
            socialContract?.invalid &&
            (socialContract?.dirty || socialContract?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="socialContract?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray"
          >Contrato Social{{ documentType?.value === 0 ? '*' : '' }}</mat-label
        >
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="socialContract"
            id="socialContract"
            formControlName="socialContract"
            (change)="changeFile($event, 'socialContract')"
            [accept]="profileFileAccepted"
            [required]="documentType?.value === 0"
          />
          <label for="socialContract" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['socialContract'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('socialContract')"
          *ngIf="_files['socialContract']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div>
      <div class="my-8">
        <mat-radio-group
          formControlName="documentType"
          [(ngModel)]="_docData.documentType"
        >
          <mat-radio-button [value]="1" [checked]="documentType?.value === 1"
            >Estatuto Social</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="documentType?.value === 1">
        <div
          *ngIf="bylaws?.invalid && (bylaws?.dirty || bylaws?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="bylaws?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray"
          >Estatuto Social{{ documentType?.value === 1 ? '*' : '' }}</mat-label
        >
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="bylaws"
            id="bylaws"
            formControlName="bylaws"
            (change)="changeFile($event, 'bylaws')"
            [accept]="profileFileAccepted"
            [required]="documentType?.value === 1"
          />
          <label for="bylaws" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{ _files['bylaws'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('bylaws')"
          *ngIf="_files['bylaws']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div>
      <div class="my-8">
        <mat-radio-group
          formControlName="documentType"
          [(ngModel)]="_docData.documentType"
        >
          <mat-radio-button [value]="2" [checked]="documentType?.value === 2"
            >Ata</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="documentType?.value === 2">
        <div
          *ngIf="ata?.invalid && (ata?.dirty || ata?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="ata?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray"
          >Ata{{ documentType?.value === 2 ? '*' : '' }}</mat-label
        >
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="ata"
            id="ata"
            formControlName="ata"
            (change)="changeFile($event, 'ata')"
            [accept]="profileFileAccepted"
            [required]="documentType?.value === 2"
          />
          <label for="ata" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{ _files['ata'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
            </p>
          </label>
        </div>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('ata')"
        *ngIf="_files['ata']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>

  <h2 class="mt-10 mb-6 text-blue">Documentos Complementares</h2>

  <div class="grid grid-cols-2 gap-8 my-5">
    <div>
      <div>
        <div
          *ngIf="
            proofResidence?.invalid &&
            (proofResidence?.dirty || proofResidence?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="proofResidence?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray"
          >Comprovante de Endereço*</mat-label
        >
        <div class="input-file-container mt-1">
          <input
            type="file"
            name="proofResidence"
            id="proofResidence"
            formControlName="proofResidence"
            (change)="changeFile($event, 'proofResidence')"
            [accept]="profileFileAccepted"
            required
          />
          <label for="proofResidence" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['proofResidence'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('proofResidence')"
          *ngIf="_files['proofResidence']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div>
      <div>
        <div
          *ngIf="balance?.invalid && (balance?.dirty || balance?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="balance?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray">Balanço*</mat-label>
        <div class="input-file-container mt-1">
          <input
            type="file"
            name="balance"
            id="balance"
            formControlName="balance"
            (change)="changeFile($event, 'balance')"
            [accept]="profileFileAccepted"
            required
          />
          <label for="balance" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{ _files['balance'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('balance')"
          *ngIf="_files['balance']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-8 my-5">
    <div>
      <div>
        <div
          *ngIf="
            billingsLastTwelveMonths?.invalid &&
            (billingsLastTwelveMonths?.dirty ||
              billingsLastTwelveMonths?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="billingsLastTwelveMonths?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-label class="text-sm text-gray"
          >Faturamentos Últimos 12 meses*</mat-label
        >
        <div class="input-file-container mt-1">
          <input
            type="file"
            name="billingsLastTwelveMonths"
            id="billingsLastTwelveMonths"
            formControlName="billingsLastTwelveMonths"
            (change)="changeFile($event, 'billingsLastTwelveMonths')"
            [accept]="profileFileAccepted"
            required
          />
          <label
            for="billingsLastTwelveMonths"
            class="flex align-center justify-start"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['billingsLastTwelveMonths']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('billingsLastTwelveMonths')"
          *ngIf="_files['billingsLastTwelveMonths']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div>
      <mat-label class="text-sm text-gray"
        >Último DEFIS encerrado em 31/12</mat-label
      >
      <div class="input-file-container mt-1">
        <input
          type="file"
          name="defis"
          id="defis"
          formControlName="defis"
          (change)="changeFile($event, 'defis')"
          [accept]="profileFileAccepted"
        />
        <label for="defis" class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{ _files['defis'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('defis')"
        *ngIf="_files['defis']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>

  <div class="grid grid-cols-2 gap-8 my-5">
    <div>
      <mat-label class="text-sm text-gray">Comprovante de Entrega</mat-label>
      <div class="input-file-container mt-1">
        <input
          type="file"
          name="deliveryReceipt"
          id="deliveryReceipt"
          formControlName="deliveryReceipt"
          (change)="changeFile($event, 'deliveryReceipt')"
          [accept]="profileFileAccepted"
        />
        <label for="deliveryReceipt" class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{
              _files['deliveryReceipt'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
            }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('deliveryReceipt')"
        *ngIf="_files['deliveryReceipt']"
        >Baixar Arquivo Anexado</span
      >
    </div>
    <div>
      <mat-label class="text-sm text-gray">Extrato PGDAS</mat-label>
      <div class="input-file-container mt-1">
        <input
          type="file"
          name="pgdas"
          id="pgdas"
          formControlName="pgdas"
          (change)="changeFile($event, 'pgdas')"
          [accept]="profileFileAccepted"
        />
        <label for="pgdas" class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{ _files['pgdas'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('pgdas')"
        *ngIf="_files['pgdas']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="next()">
      Prosseguir
    </button>
  </div>
</form>
