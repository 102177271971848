import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../core/services/auth.service';
import { UserTypeEnum } from '../../enums/user-session.enum';
import { NavigationType } from '../../../shared/types/navigation.type';

@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
  userType: UserTypeEnum = UserTypeEnum.NONE;
  allRoutes: Array<NavigationType> = [
    {
      title: 'Meu Cadastro',
      route: '/perfil/pf/cadastro',
      icon: 'perm_identity',
      canActivate: [UserTypeEnum.PHYSICAL_PERSON]
    },
    {
      title: 'Meu Cadastro',
      route: '/perfil/pj/cadastro',
      icon: 'perm_identity',
      canActivate: [UserTypeEnum.LEGAL_PERSON]
    },
    {
      title: 'Meu Cadastro',
      route: '/perfil/corban/cadastro',
      icon: 'perm_identity',
      canActivate: [UserTypeEnum.CORBAN]
    },
    {
      title: 'Cotações e Fechamentos',
      route: '/cotacao/listar',
      icon: 'currency_exchange'
    },
    {
      title: 'Meus Clientes',
      route: '/clientes/listar',
      icon: 'group',
      canActivate: [UserTypeEnum.CORBAN, UserTypeEnum.CORBAN_CONTRIBUTOR]
    },
    {
      title: 'Time',
      route: '/colaborador/listar',
      icon: 'group',
      canActivate: [
        UserTypeEnum.CORBAN,
        UserTypeEnum.LEGAL_PERSON,
        UserTypeEnum.CORBAN_CONTRIBUTOR,
        UserTypeEnum.LEGAL_PERSON_CONTRIBUTOR
      ]
    },
    { title: 'Configurações', route: '/configuracoes', icon: 'settings' },
    { title: 'Ajuda', route: '/ajuda', icon: 'help' }
  ];
  availableRoutes: Array<NavigationType> = [];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.watchLoggedIn().subscribe({
      next: () => {
        this.authService
          .getCurrentUserType()
          .then((currentUserType: UserTypeEnum) => {
            this.availableRoutes = this.allRoutes.filter(
              (appRouter: NavigationType) =>
                appRouter.canActivate === undefined ||
                appRouter.canActivate.indexOf(currentUserType) > -1
            );
          });
      }
    });
  }

  logout(): void {
    this.authService.logout();
  }
}
