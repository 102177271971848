<div class="container mx-auto my-20 flex items-center justify-center">
  <div class="content-area">
    <div class="div-circle flex items-center justify-center">
      <mat-icon class="text-blue text-4xl h-9 w-9">
        assignment_turned_in
      </mat-icon>
    </div>
    <h2 class="text-3xl text-neutral-600 my-5 font-bold">Meu Cadastro</h2>
    <p class="text-neutral-600 text-center text-lg">
      Clique no botão abaixo e complete o cadastro do seu perfil para começar as
      fazer remessas internacionais.
    </p>
    <button
      mat-button
      class="w-52 h-12 bg-blue text-neutral-100 my-6"
      (click)="changeVisibility()"
      type="submit"
    >
      Iniciar Meu Cadastro
    </button>
  </div>
</div>
