<div>
  <h3 class="mt-10 text-blue">
    Representante {{ (representativeNumber || 0) + 1 }}
  </h3>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Tipo</mat-label>
    <p>{{ representativeType.name }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>CPF</mat-label>
    <p>{{ _representative.cpf | mask: '000.000.000-00' }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Nome do Responsável</mat-label>
    <p>{{ _representative.name }}</p>
  </div>

  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Email de Contato</mat-label>
    <p>{{ _representative.email }}</p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Telefone de Contato</mat-label>
    <p>
      {{
        _representative.phone
          | mask
            : (_representative.phone.length > 10
                ? '(00) 0 0000-0000'
                : '(00) 0000-0000')
      }}
    </p>
  </div>
  <div class="grid grid-cols-2 gap-2 my-8 data-item">
    <mat-label>Estado Civil</mat-label>
    <p>{{ maritalStatus.name }}</p>
  </div>
  <div *ngIf="isMarried()">
    <h4 class="mt-6 matrial-subTitle"><b>Dados do Cônjuge</b></h4>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>CPF</mat-label>
      <p>{{ _representative.spouseCpf | mask: '000.000.000-00' }}</p>
    </div>
    <div class="grid grid-cols-2 gap-2 my-8 data-item">
      <mat-label>Nome do Cônjuge</mat-label>
      <p>{{ _representative.spouseName }}</p>
    </div>
  </div>
  <h4 class="mt-6 matrial-subTitle"><b>Arquivos e Documentos</b></h4>
  <div class="grid grid-cols-2 gap-2 mb-8 gap-x-8">
    <div class="w-full" *ngIf="_files['proofResidence']">
      <mat-label class="text-sm text-gray">Comprovante de Endereço*</mat-label>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('proofResidence')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
    <div class="w-full" *ngIf="_files['letterOfAttorney']">
      <mat-label class="text-sm text-gray">Procuração</mat-label>
      <div
        class="input-file-container mt-1 w-full"
        (click)="download('letterOfAttorney')"
      >
        <label class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_download</mat-icon>
          <span class="text-cyan">Baixar Arquivo</span>
        </label>
      </div>
    </div>
  </div>
</div>
