import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class MaritalStatusService extends StaticDataService {
  constructor() {
    super();
    this.data = [
      {
        id: 0,
        name: 'Solteiro'
      },
      {
        id: 1,
        name: 'Casado'
      },
      {
        id: 2,
        name: 'União Estável'
      },
      {
        id: 2,
        name: 'Viúvo'
      }
    ];
  }
}
