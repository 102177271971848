import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicDataPhysicalPersonType } from '../../../../types/profile/physical-person.type';
import { defaultFormErros } from '../../../../enums/default-form-errors.enum';
import { FormErrorType } from '../../../../types/form-error.type';
import { CustomValidator } from '../../../../custom.validator';
import { StaticDataType } from '../../../../types/static-data.type';
import { EstimatedOperationValueService } from '../../../../services/static-data/estimated-operation-value.service';
import { GenderService } from '../../../../services/static-data/gender.service';
import { MaritalStatusService } from '../../../../services/static-data/marital-status.service';
import { MonthlyIncomeService } from '../../../../services/static-data/monthly-income.service';
import { FormService } from '../../../../services/form.service';

@Component({
  selector: 'app-basic-data-form-pf',
  templateUrl: './basic-data-form-pf.component.html',
  styleUrls: ['./basic-data-form-pf.component.scss']
})
export class BasicDataFormPfComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  estimatedOperationValues: Array<StaticDataType>;
  genders: Array<StaticDataType>;
  maritalStatuses: Array<StaticDataType>;
  monthlyIncomes: Array<StaticDataType>;
  @Input() basicData: BasicDataPhysicalPersonType;
  @Output() basicDataChange = new EventEmitter<BasicDataPhysicalPersonType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() nextChange = new EventEmitter<void>();

  constructor(
    private estimatedOperationValueService: EstimatedOperationValueService,
    private genderService: GenderService,
    private maritalStatusService: MaritalStatusService,
    private monthlyIncomeService: MonthlyIncomeService,
    private formService: FormService
  ) {
    this.basicData = {} as BasicDataPhysicalPersonType;
    this.estimatedOperationValues = [];
    this.genders = [];
    this.maritalStatuses = [];
    this.monthlyIncomes = [];

    this.form = new FormGroup({
      estimatedOperationValue: new FormControl(
        this.basicData.estimatedOperationValue,
        [Validators.required]
      ),
      name: new FormControl(this.basicData.name, [Validators.required]),
      cpf: new FormControl(this.basicData.cpf, [
        Validators.required,
        CustomValidator.cpfValidator
      ]),
      birthDate: new FormControl(this.basicData.birthDate, [
        Validators.required
      ]),
      gender: new FormControl(this.basicData.gender, [Validators.required]),
      maritalStatus: new FormControl(this.basicData.maritalStatus, [
        Validators.required
      ]),
      motherName: new FormControl(this.basicData.motherName, [
        Validators.required
      ]),
      fatherName: new FormControl(this.basicData.fatherName, [
        Validators.required
      ]),
      profession: new FormControl(this.basicData.profession, [
        Validators.required
      ]),
      monthlyIncome: new FormControl(this.basicData.monthlyIncome, [
        Validators.required
      ])
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit() {
    this.estimatedOperationValue.valueChanges.subscribe(
      (estimatedValue: number) => {
        if (Number(estimatedValue) === 0) {
          this.motherName.setValidators(null);
          this.fatherName.setValidators(null);
          this.profession.setValidators(null);
        } else if (Number(estimatedValue) === 1) {
          this.motherName.setValidators([Validators.required]);
          this.fatherName.setValidators([Validators.required]);
          this.profession.setValidators([Validators.required]);
        }
        this.motherName.updateValueAndValidity();
        this.fatherName.updateValueAndValidity();
        this.profession.updateValueAndValidity();
      }
    );

    this.estimatedOperationValueService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.estimatedOperationValues = data;
      }
    });
    this.genderService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.genders = data;
      }
    });
    this.maritalStatusService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.maritalStatuses = data;
      }
    });
    this.monthlyIncomeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.monthlyIncomes = data;
      }
    });
  }

  get estimatedOperationValue() {
    return this.form.get('estimatedOperationValue')!;
  }

  get name() {
    return this.form.get('name')!;
  }

  get cpf() {
    return this.form.get('cpf')!;
  }

  get birthDate() {
    return this.form.get('birthDate')!;
  }

  get gender() {
    return this.form.get('gender')!;
  }

  get maritalStatus() {
    return this.form.get('maritalStatus')!;
  }

  get motherName() {
    return this.form.get('motherName')!;
  }

  get fatherName() {
    return this.form.get('fatherName')!;
  }

  get profession() {
    return this.form.get('profession')!;
  }

  get monthlyIncome() {
    return this.form.get('monthlyIncome')!;
  }

  updateData() {
    this.basicData = this.form.value;
    this.basicDataChange.emit(this.basicData);
    this.validChange.emit(this.form.valid);
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }
}
