import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { UserTypeEnum } from '../../shared/enums/user-session.enum';

@Injectable({
  providedIn: 'root'
})
export class TeamGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkProfile();
  }

  canLoad(
    _route: Route,
    _segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.checkProfile();
  }

  checkProfile(): Promise<boolean> {
    return this.authService
      .getCurrentUserType()
      .then((currentUserType: UserTypeEnum) => {
        if (
          [
            UserTypeEnum.LEGAL_PERSON,
            UserTypeEnum.LEGAL_PERSON_CONTRIBUTOR,
            UserTypeEnum.CORBAN,
            UserTypeEnum.CORBAN_CONTRIBUTOR
          ].indexOf(currentUserType) > -1
        ) {
          return true;
        }
        this.router.navigate(['/']);
        return false;
      });
  }
}
