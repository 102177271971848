<h2 class="mt-10 text-blue">Documento Pessoal</h2>
<form [formGroup]="form">
  <div>
    <div
      *ngIf="
        personalDocumentType?.invalid &&
        (personalDocumentType?.dirty || personalDocumentType?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="personalDocumentType?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Tipo de Documento</mat-label>
      <mat-select
        formControlName="personalDocumentType"
        [(ngModel)]="_docsPhysicalPerson.personalDocumentType"
        required
      >
        <mat-option
          *ngFor="let personalDocumentType of personalDocumentTypes"
          [value]="personalDocumentType.id"
          >{{ personalDocumentType.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <div
      *ngIf="
        personalDocumentNumber?.invalid &&
        (personalDocumentNumber?.dirty || personalDocumentNumber?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="personalDocumentNumber?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Número do Documento</mat-label>
      <input
        matInput
        required
        formControlName="personalDocumentNumber"
        [(ngModel)]="_docsPhysicalPerson.personalDocumentNumber"
      />
    </mat-form-field>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="
          personalDocumentIssuer?.invalid &&
          (personalDocumentIssuer?.dirty || personalDocumentIssuer?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="personalDocumentIssuer?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Órgão Expedidor</mat-label>
        <input
          matInput
          required
          formControlName="personalDocumentIssuer"
          [(ngModel)]="_docsPhysicalPerson.personalDocumentIssuer"
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          personalDocumentIssueDate?.invalid &&
          (personalDocumentIssueDate?.dirty ||
            personalDocumentIssueDate?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="personalDocumentIssueDate?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field
        appearance="outline"
        class="input-size"
        (click)="issueDatePicker.open()"
      >
        <mat-label>Data de Emissão</mat-label>
        <input
          formControlName="personalDocumentIssueDate"
          [(ngModel)]="_docsPhysicalPerson.personalDocumentIssueDate"
          required
          matInput
          [matDatepicker]="issueDatePicker"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="issueDatePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #issueDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 gap-x-8">
    <div>
      <div
        *ngIf="
          personalDocument?.invalid &&
          (personalDocument?.dirty || personalDocument?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="personalDocument?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <div class="input-file-container w-full">
        <input
          type="file"
          name="personal-document"
          id="personal-document"
          formControlName="personalDocument"
          (change)="changeFile($event, 'personalDocument')"
          [accept]="profileFileAccepted"
          required
        />
        <label for="personal-document" class="flex align-center justify-start">
          <mat-icon class="text-cyan">file_upload</mat-icon>
          <p class="text-cyan">
            {{
              _files['personalDocument'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
            }}
          </p>
        </label>
      </div>
      <span
        class="text-navy cursor-pointer"
        (click)="download('personalDocument')"
        *ngIf="_files['personalDocument']"
        >Baixar Arquivo Anexado</span
      >
    </div>
  </div>
  <div *ngIf="_estimatedValue === 1">
    <h2 class="mt-10 mb-6 text-blue">Comprovante de Endereço*</h2>
    <div
      *ngIf="
        proofResidence?.invalid &&
        (proofResidence?.dirty || proofResidence?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="proofResidence?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div class="input-file-container w-full">
          <input
            type="file"
            name="proof-of-residence"
            id="proof-of-residence"
            formControlName="proofResidence"
            (change)="changeFile($event, 'proofResidence')"
            [accept]="profileFileAccepted"
            [required]="_estimatedValue === 1"
          />
          <label
            for="proof-of-residence"
            class="flex align-center justify-start"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['proofResidence'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('proofResidence')"
          *ngIf="_files['proofResidence']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <h2 class="mt-10 mb-6 text-blue">
      Declaração de CBE (Capital Brasileiro no Exterior)
    </h2>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div class="input-file-container w-full">
          <input
            type="file"
            name="cbe"
            id="cbe"
            formControlName="cbe"
            (change)="changeFile($event, 'cbe')"
            [accept]="profileFileAccepted"
          />
          <label for="cbe" class="flex align-center justify-start">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{ _files['cbe'] ? 'Alterar Arquivo' : 'Anexar Arquivo' }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('cbe')"
          *ngIf="_files['cbe']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>

    <h2 class="mt-10 mb-6 text-blue">Comprovação de Renda</h2>
    <div>
      <div
        *ngIf="
          incomeProof?.invalid && (incomeProof?.dirty || incomeProof?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="incomeProof?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-radio-group
        formControlName="incomeProof"
        class="text-gray w-full gap-6 flex mb-5 px-1"
        [required]="_estimatedValue === 1"
      >
        <mat-radio-button [value]="0" [checked]="incomeProof?.value === 0"
          >Imposto de Renda + Recibo</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div
      class="grid grid-cols-2 gap-2 gap-x-8"
      *ngIf="incomeProof?.value === 0"
    >
      <div>
        <div
          *ngIf="
            incomeTaxDeclaration?.invalid &&
            (incomeTaxDeclaration?.dirty || incomeTaxDeclaration?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="incomeTaxDeclaration?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Imposto de Renda - Declaração</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="income-tax-declaration"
            id="income-tax-declaration"
            formControlName="incomeTaxDeclaration"
            (change)="changeFile($event, 'incomeTaxDeclaration')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 0"
          />
          <label
            for="income-tax-declaration"
            class="flex align-center justify-center"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['incomeTaxDeclaration']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('incomeTaxDeclaration')"
          *ngIf="_files['incomeTaxDeclaration']"
          >Baixar Arquivo Anexado</span
        >
      </div>
      <div>
        <div
          *ngIf="
            incomeTaxReceipt?.invalid &&
            (incomeTaxReceipt?.dirty || incomeTaxReceipt?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="incomeTaxReceipt?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Imposto de Renda - Recibo</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="income-tax-receipt"
            id="income-tax-receipt"
            formControlName="incomeTaxReceipt"
            (change)="changeFile($event, 'incomeTaxReceipt')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 0"
          />
          <label
            for="income-tax-receipt"
            class="flex align-center justify-center"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['incomeTaxReceipt']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('incomeTaxReceipt')"
          *ngIf="_files['incomeTaxReceipt']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div class="mt-6">
      <mat-radio-group
        formControlName="incomeProof"
        class="text-gray w-full gap-6 flex mb-5 px-1"
        [required]="_estimatedValue === 1"
      >
        <mat-radio-button [value]="1" [checked]="incomeProof?.value === 1"
          >Holerite (últimos 3 meses)</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div
      class="grid grid-cols-3 gap-2 gap-x-8"
      *ngIf="incomeProof?.value === 1"
    >
      <div>
        <div
          *ngIf="
            payslipMonth1?.invalid &&
            (payslipMonth1?.dirty || payslipMonth1?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="payslipMonth1?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 1</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="payslip-1"
            id="payslip-1"
            formControlName="payslipMonth1"
            (change)="changeFile($event, 'payslipMonth1')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 1"
          />
          <label for="payslip-1" class="flex align-center justify-center">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['payslipMonth1'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('payslipMonth1')"
          *ngIf="_files['payslipMonth1']"
          >Baixar Arquivo Anexado</span
        >
      </div>
      <div>
        <div
          *ngIf="
            payslipMonth2?.invalid &&
            (payslipMonth2?.dirty || payslipMonth2?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="payslipMonth2?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 2</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="payslip-2"
            id="payslip-2"
            formControlName="payslipMonth2"
            (change)="changeFile($event, 'payslipMonth2')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 1"
          />
          <label for="payslip-2" class="flex align-center justify-center">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['payslipMonth2'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('payslipMonth2')"
          *ngIf="_files['payslipMonth2']"
          >Baixar Arquivo Anexado</span
        >
      </div>
      <div>
        <div
          *ngIf="
            payslipMonth3?.invalid &&
            (payslipMonth3?.dirty || payslipMonth3?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="payslipMonth3?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 3</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="payslip-3"
            id="payslip-3"
            formControlName="payslipMonth3"
            (change)="changeFile($event, 'payslipMonth3')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 1"
          />
          <label for="payslip-3" class="flex align-center justify-center">
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['payslipMonth3'] ? 'Alterar Arquivo' : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('payslipMonth3')"
          *ngIf="_files['payslipMonth3']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
    <div class="mt-6">
      <mat-radio-group
        formControlName="incomeProof"
        class="text-gray w-full gap-6 flex mb-5 px-1"
        [required]="_estimatedValue === 1"
      >
        <mat-radio-button [value]="2" [checked]="incomeProof?.value === 2"
          >Extrato de Conta Corrente (últimos 3 meses)</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div
      class="grid grid-cols-3 gap-2 gap-x-8"
      *ngIf="incomeProof?.value === 2"
    >
      <div>
        <div
          *ngIf="
            currentAccountStatementMonth1?.invalid &&
            (currentAccountStatementMonth1?.dirty ||
              currentAccountStatementMonth1?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="currentAccountStatementMonth1?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 1</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="current-account-statement-1"
            id="current-account-statement-1"
            formControlName="currentAccountStatementMonth1"
            (change)="changeFile($event, 'currentAccountStatementMonth1')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 2"
          />
          <label
            for="current-account-statement-1"
            class="flex align-center justify-center"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['currentAccountStatementMonth1']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('currentAccountStatementMonth1')"
          *ngIf="_files['currentAccountStatementMonth1']"
          >Baixar Arquivo Anexado</span
        >
      </div>
      <div>
        <div
          *ngIf="
            currentAccountStatementMonth2?.invalid &&
            (currentAccountStatementMonth2?.dirty ||
              currentAccountStatementMonth2?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="currentAccountStatementMonth2?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 2</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="current-account-statement-2"
            id="current-account-statement-2"
            formControlName="currentAccountStatementMonth2"
            (change)="changeFile($event, 'currentAccountStatementMonth2')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 2"
          />
          <label
            for="current-account-statement-2"
            class="flex align-center justify-center"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['currentAccountStatementMonth2']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('currentAccountStatementMonth2')"
          *ngIf="_files['currentAccountStatementMonth2']"
          >Baixar Arquivo Anexado</span
        >
      </div>
      <div>
        <div
          *ngIf="
            currentAccountStatementMonth3?.invalid &&
            (currentAccountStatementMonth3?.dirty ||
              currentAccountStatementMonth3?.touched)
          "
          class="invalid-feedback"
        >
          <div *ngIf="currentAccountStatementMonth3?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <span class="text-gray-light">Mês 3</span>
        <div class="input-file-container mt-1 w-full">
          <input
            type="file"
            name="current-account-statement-3"
            id="current-account-statement-3"
            formControlName="currentAccountStatementMonth3"
            (change)="changeFile($event, 'currentAccountStatementMonth3')"
            [accept]="profileFileAccepted"
            [required]="incomeProof?.value === 2"
          />
          <label
            for="current-account-statement-3"
            class="flex align-center justify-center"
          >
            <mat-icon class="text-cyan">file_upload</mat-icon>
            <p class="text-cyan">
              {{
                _files['currentAccountStatementMonth3']
                  ? 'Alterar Arquivo'
                  : 'Anexar Arquivo'
              }}
            </p>
          </label>
        </div>
        <span
          class="text-navy cursor-pointer"
          (click)="download('currentAccountStatementMonth3')"
          *ngIf="_files['currentAccountStatementMonth3']"
          >Baixar Arquivo Anexado</span
        >
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mt-10 gap-x-8">
    <button
      mat-button
      color="cyan"
      mat-stroked-button
      class="w-full border-cyan text-cyan"
      (click)="previous()"
    >
      Voltar
    </button>
    <button mat-button class="w-full bg-cyan text-neutral-100" (click)="next()">
      Prosseguir
    </button>
  </div>
</form>
