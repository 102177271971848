import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BasicDataLegalPersonType } from '../../../../types/profile/legal-person.type';
import { defaultFormErros } from '../../../../enums/default-form-errors.enum';
import { FormErrorType } from '../../../../types/form-error.type';
import { CustomValidator } from '../../../../custom.validator';
import { StaticDataType } from '../../../../types/static-data.type';
import { AddressService } from '../../../../services/static-data/address.service';
import { FormService } from '../../../../services/form.service';

@Component({
  selector: 'app-basic-data-form-pj',
  templateUrl: './basic-data-form-pj.component.html',
  styleUrls: ['./basic-data-form-pj.component.scss']
})
export class BasicDataFormPjComponent implements OnInit {
  form!: FormGroup;
  errorMessages: FormErrorType = defaultFormErros;
  addressTypes: Array<StaticDataType>;
  @Input() basicData: BasicDataLegalPersonType;
  @Output() basicDataChange = new EventEmitter<BasicDataLegalPersonType>();
  @Output() validChange = new EventEmitter<boolean>();
  @Output() nextChange = new EventEmitter<void>();

  constructor(
    private addressTypeService: AddressService,
    private formService: FormService
  ) {
    this.basicData = {} as BasicDataLegalPersonType;
    this.addressTypes = [];
    this.form = new FormGroup({
      tradingName: new FormControl(this.basicData.tradingName, [
        Validators.required
      ]),
      companyName: new FormControl(this.basicData.companyName, [
        Validators.required
      ]),
      cnpj: new FormControl(this.basicData.cnpj, [
        Validators.required,
        CustomValidator.cnpjValidator
      ]),
      accountableName: new FormControl(this.basicData.accountableName, [
        Validators.required
      ]),
      accountableEmail: new FormControl(this.basicData.accountableEmail, [
        Validators.required,
        CustomValidator.emailValidator()
      ]),
      accountablePhone: new FormControl(this.basicData.accountablePhone, [
        Validators.required
      ]),
      allowedEmailUse: new FormControl(this.basicData.allowedEmailUse, [
        Validators.required
      ]),
      addressType: new FormControl(this.basicData.addressType, [
        Validators.required
      ]),
      address: new FormControl(this.basicData.address, [Validators.required]),
      addressComplement: new FormControl(this.basicData.addressComplement, []),
      zipCode: new FormControl(this.basicData.zipCode, [Validators.required]),
      addressNumber: new FormControl(this.basicData.addressNumber, []),
      neighborhood: new FormControl(this.basicData.neighborhood, [
        Validators.required
      ]),
      country: new FormControl(this.basicData.country, [Validators.required]),
      city: new FormControl(this.basicData.city, [Validators.required]),
      federatedState: new FormControl(this.basicData.federatedState, [
        Validators.required
      ])
    });

    this.form.valueChanges.subscribe(() => {
      if (this.form.touched) {
        this.updateData();
      }
    });
  }

  ngOnInit() {
    this.addressType?.valueChanges.subscribe((addressType: number) => {
      if (Number(addressType) === 0) {
        this.country.setValidators(null);
      } else if (Number(addressType) === 1) {
        this.country.setValidators([Validators.required]);
      }
      this.country.updateValueAndValidity();
    });

    this.addressTypeService.list().subscribe({
      next: (data: Array<StaticDataType>) => {
        this.addressTypes = data;
      }
    });
  }

  get tradingName() {
    return this.form.get('tradingName')!;
  }

  get companyName() {
    return this.form.get('companyName')!;
  }

  get accountableName() {
    return this.form.get('accountableName')!;
  }

  get cnpj() {
    return this.form.get('cnpj')!;
  }

  get accountableEmail() {
    return this.form.get('accountableEmail')!;
  }

  get accountablePhone() {
    return this.form.get('accountablePhone')!;
  }

  get allowedEmailUse() {
    return this.form.get('allowedEmailUse')!;
  }

  get addressType() {
    return this.form.get('addressType')!;
  }

  get address() {
    return this.form.get('address')!;
  }

  get addressComplement() {
    return this.form.get('addressComplement')!;
  }

  get zipCode() {
    return this.form.get('zipCode')!;
  }

  get addressNumber() {
    return this.form.get('addressNumber')!;
  }

  get neighborhood() {
    return this.form.get('neighborhood')!;
  }

  get country() {
    return this.form.get('country')!;
  }

  get city() {
    return this.form.get('city')!;
  }

  get federatedState() {
    return this.form.get('federatedState')!;
  }

  updateData() {
    this.basicData = this.form.value;
    this.basicDataChange.emit(this.basicData);
    this.validChange.emit(this.form.valid);
  }

  next() {
    if (this.form.invalid) {
      this.formService.toucheControls(this.form);
      return;
    }
    this.updateData();
    this.nextChange.emit();
  }
}
