import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-pre-register',
  templateUrl: './pre-register.component.html',
  styleUrls: ['./pre-register.component.scss']
})
export class PreRegisterComponent {
  @Output() changeVisibilityEmitter = new EventEmitter();
  constructor() {}

  changeVisibility() {
    this.changeVisibilityEmitter.emit();
  }
}
