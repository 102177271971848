<h2 class="mt-10 text-blue">Valor Estimado a Operar</h2>
<form [formGroup]="form">
  <div>
    <div
      *ngIf="
        estimatedOperationValue?.invalid &&
        (estimatedOperationValue?.dirty || estimatedOperationValue?.touched)
      "
      class="invalid-feedback"
    >
      <div *ngIf="estimatedOperationValue?.errors?.['required']">
        {{ errorMessages.REQUIRED }}
      </div>
    </div>
    <mat-form-field appearance="outline" class="input-size">
      <mat-label>Selecione uma opção</mat-label>
      <mat-select
        formControlName="estimatedOperationValue"
        [(ngModel)]="basicData.estimatedOperationValue"
        required
      >
        <mat-option
          *ngFor="let estimatedOperationValue of estimatedOperationValues"
          [value]="estimatedOperationValue.id"
          >{{ estimatedOperationValue.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="basic-data"
    *ngIf="[0, 1].includes(estimatedOperationValue?.value)"
  >
    <h2 class="mt-10 mb-6 text-blue">Informações Básicas</h2>
    <div>
      <div
        *ngIf="name?.invalid && (name?.dirty || name?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="name?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Nome Completo</mat-label>
        <input
          formControlName="name"
          [(ngModel)]="basicData.name"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div>
        <div
          *ngIf="cpf?.invalid && (cpf?.dirty || cpf?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="cpf?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
          <div *ngIf="cpf?.errors?.['cpfInvalid']">
            {{ errorMessages.INVALID_CPF }}
          </div>
        </div>
        <mat-form-field appearance="outline" class="input-size">
          <mat-label>CPF</mat-label>
          <input
            [(ngModel)]="basicData.cpf"
            formControlName="cpf"
            required
            matInput
            mask="000.000.000-00"
            placeholder="xxx.xxx.xxx-xx"
          />
        </mat-form-field>
      </div>
      <div>
        <div
          *ngIf="birthDate?.invalid && (birthDate?.dirty || birthDate?.touched)"
          class="invalid-feedback"
        >
          <div *ngIf="birthDate?.errors?.['required']">
            {{ errorMessages.REQUIRED }}
          </div>
        </div>
        <mat-form-field
          appearance="outline"
          class="input-size"
          (click)="birthdaPicker.open()"
        >
          <mat-label>Data de Nascimento</mat-label>
          <input
            [(ngModel)]="basicData.birthDate"
            formControlName="birthDate"
            required
            matInput
            [matDatepicker]="birthdaPicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="birthdaPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #birthdaPicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div>
      <div
        *ngIf="gender?.invalid && (gender?.dirty || gender?.touched)"
        class="invalid-feedback"
      >
        <div *ngIf="gender?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Gênero</mat-label>
        <mat-select
          formControlName="gender"
          required
          [(ngModel)]="basicData.gender"
        >
          <mat-option *ngFor="let gender of genders" [value]="gender.id">{{
            gender.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          maritalStatus?.invalid &&
          (maritalStatus?.dirty || maritalStatus?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="maritalStatus?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Estado Civil</mat-label>
        <mat-select
          formControlName="maritalStatus"
          [(ngModel)]="basicData.maritalStatus"
          required
        >
          <mat-option
            *ngFor="let maritalStatus of maritalStatuses"
            [value]="maritalStatus.id"
            >{{ maritalStatus.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="estimatedOperationValue?.value === 1">
      <div
        *ngIf="
          motherName?.invalid && (motherName?.dirty || motherName?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="motherName?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Nome da mãe</mat-label>
        <input
          formControlName="motherName"
          [(ngModel)]="basicData.motherName"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div *ngIf="estimatedOperationValue?.value === 1">
      <div
        *ngIf="
          fatherName?.invalid && (fatherName?.dirty || fatherName?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="fatherName?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Nome do pai</mat-label>
        <input
          formControlName="fatherName"
          [(ngModel)]="basicData.fatherName"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div *ngIf="estimatedOperationValue?.value === 1">
      <div
        *ngIf="
          profession?.invalid && (profession?.dirty || profession?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="profession?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Profissão</mat-label>
        <input
          formControlName="profession"
          [(ngModel)]="basicData.profession"
          required
          matInput
        />
      </mat-form-field>
    </div>
    <div>
      <div
        *ngIf="
          monthlyIncome?.invalid &&
          (monthlyIncome?.dirty || monthlyIncome?.touched)
        "
        class="invalid-feedback"
      >
        <div *ngIf="monthlyIncome?.errors?.['required']">
          {{ errorMessages.REQUIRED }}
        </div>
      </div>
      <mat-form-field appearance="outline" class="input-size">
        <mat-label>Renda Média Mensal</mat-label>
        <mat-select
          formControlName="monthlyIncome"
          [(ngModel)]="basicData.monthlyIncome"
          required
        >
          <mat-option
            *ngFor="let monthlyIncome of monthlyIncomes"
            [value]="monthlyIncome.id"
            >{{ monthlyIncome.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="grid grid-cols-2 gap-2 gap-x-8">
      <div></div>
      <button
        mat-button
        class="w-full bg-cyan text-neutral-100"
        (click)="next()"
      >
        Prosseguir
      </button>
    </div>
  </div>
</form>
