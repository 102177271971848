import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { TeamGuard } from './modules/collaborator/team-guard';
import { ClientGuard } from './modules/client/client-guard.service';
import { DefaultRedirectGuard } from './core/guards/default-redirect.guard';

const routes: Routes = [
  {
    path: 'cliente',
    loadChildren: () =>
      import('./modules/client/client.module').then((m) => m.ClientModule),
    canLoad: [MsalGuard, ClientGuard]
  },
  {
    path: 'colaborador',
    loadChildren: () =>
      import('./modules/collaborator/team.module').then((m) => m.TeamModule),
    canLoad: [MsalGuard, TeamGuard]
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'cotacao',
    loadChildren: () =>
      import('./modules/quotation/quotation.module').then(
        (m) => m.QuotationModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: 'configuracoes',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [MsalGuard]
  },
  {
    path: 'ajuda',
    loadChildren: () =>
      import('./modules/help/help.module').then((m) => m.HelpModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'erro',
    loadChildren: () =>
      import('./modules/error/error.module').then((m) => m.ErrorModule)
  },
  {
    path: 'nao-autorizado',
    loadChildren: () =>
      import('./modules/unauthorized/unauthorized.module').then(
        (m) => m.UnauthorizedModule
      )
  },
  { path: 'nao-encontrado', component: NotFoundComponent },
  {
    path: '',
    pathMatch: 'full',
    children: [],
    canActivate: [DefaultRedirectGuard]
  },
  { path: '**', redirectTo: '/nao-encontrado', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
