import { Component, Input } from '@angular/core';
import { FileType } from '../../../../types/profile/file.type';
import { PartnerType } from '../../../../types/profile/legal-person.type';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { defaultMessages } from '../../../../data/default-messages';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-partner-data-detail',
  templateUrl: './partner-data-detail.component.html',
  styleUrls: ['./partner-data-detail.component.scss']
})
export class PartnerDataDetailComponent {
  _files: { [key: string]: FileType };
  _partner: PartnerType;
  @Input() partnerNumber!: number;

  constructor(
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._files = {};
    this._partner = {} as PartnerType;
  }

  @Input() set partner(partner: PartnerType) {
    this._partner = partner;
    if (this._partner.files) {
      for (const file of this._partner.files) {
        this._files[file.type] = file;
      }
    }
  }
  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }
}
