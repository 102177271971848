import { Injectable } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyCapitalService extends StaticDataService {
  constructor() {
    super();
    this.data = [
      {
        id: 0,
        name: 'De R$100,00 a R$1.000,00'
      },
      {
        id: 1,
        name: 'De R$1.000,01 a R$100.000,00'
      },
      {
        id: 2,
        name: 'De R$100.000,01 a R$1.000.000,00'
      },
      {
        id: 3,
        name: 'Acima de R$1.000.000,00'
      }
    ];
  }
}
