import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhysicalPerson } from '../../../../types/profile/physical-person.type';
import { PersonalDocumentTypeService } from '../../../../services/static-data/personal-document-type.service';
import { StaticDataType } from '../../../../types/static-data.type';
import { FileType } from '../../../../types/profile/file.type';
import { ProfileFileService } from '../../../../services/profile/profile-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { defaultMessages } from '../../../../data/default-messages';
import * as moment from 'moment';

@Component({
  selector: 'app-docs-detail-pf',
  templateUrl: './docs-detail-pf.component.html',
  styleUrls: ['./docs-detail-pf.component.scss']
})
export class DocsDetailPfComponent {
  _files: { [key: string]: FileType };
  _physicalPerson: PhysicalPerson;
  personalDocumentType: StaticDataType;
  personalDocumentIssueDate: string = '';
  @Output() previousChange = new EventEmitter<void>();
  @Output() editChange = new EventEmitter<void>();

  constructor(
    private personalDocumentTypeService: PersonalDocumentTypeService,
    private profileFileService: ProfileFileService,
    private snackBar: MatSnackBar
  ) {
    this._physicalPerson = {} as PhysicalPerson;
    this.personalDocumentType = {} as StaticDataType;
    this._files = {};
  }

  @Input() set physicalPerson(physicalPerson: PhysicalPerson) {
    this._physicalPerson = physicalPerson;
    this.personalDocumentIssueDate = moment(
      new Date(this._physicalPerson.personalDocumentIssueDate)
    ).format('DD/MM/YYYY');
    if (this._physicalPerson.status !== undefined) {
      this.personalDocumentTypeService
        .get(this._physicalPerson.personalDocumentType)
        .subscribe({
          next: (personalDocumentType: StaticDataType) => {
            this.personalDocumentType = personalDocumentType;
          }
        });
    }
    if (this._physicalPerson.files) {
      for (const file of this._physicalPerson.files) {
        this._files[file.type] = file;
      }
    }
  }

  download(type: string) {
    if (this._files[type]) {
      this.profileFileService
        .downloadTemp(this._files[type].fileKey!)
        .subscribe({
          error: () => {
            this.snackBar.open(defaultMessages.download_failed, 'Fechar', {
              panelClass: ['snack-error'],
              horizontalPosition: 'end',
              verticalPosition: 'top',
              duration: 5 * 1000
            });
          }
        });
    }
  }

  previous() {
    this.previousChange.emit();
  }

  edit() {
    this.editChange.emit();
  }
}
